import { IResourceComponentsProps, useShow, useTranslate } from "@pankod/refine-core";

import { Show, Typography } from "@pankod/refine-antd";

const { Title, Text } = Typography;

export const CrudShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("posts.fields.title")}</Title>
      <Text>{record?.title}</Text>
    </Show>
  );
};
