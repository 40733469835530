import {
  Modal,
  Form,
  ModalProps,
  FormProps,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { validateMessages } from "provider/validateMessage";
import { useEffect, useState } from "react";
import { getResource } from "resources";
import { CrudField } from "./field";

type CreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  name: string;
  onFinish: any;
};

export const CreateModal: React.FC<CreateProps> = ({
  name,
  modalProps,
  formProps,
  onFinish = false,
}) => {
  const t = useTranslate();
  const [formFields, setFormFields] = useState<any[]>([]);

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Modal {...modalProps} title={t('crud.action.create')}>
      <Form
        {...formProps}
        validateMessages={validateMessages}
        layout="vertical"
        onFinish={(values) => {
          const fieldHasValue = formFields.filter((field: any) => field.value);
          fieldHasValue.forEach((field: any) => {
            values[field.field] = field.value;
          });
          if (onFinish) return onFinish(values, formProps);
          return formProps.onFinish && formProps.onFinish(values);
        }}
      >
        {formFields.map((item) => (
          <Form.Item
            key={item.field}
            label={t(`crud.fields.${item.label}`)}
            name={item.field}
            rules={item.rules}
            valuePropName={item.type === "switch" ? "checked" : "value"}
            hidden={item.type === "hidden"}
          >
            <CrudField
              type={item.type}
              resource={item.resource}
              select={item.select}
            />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
