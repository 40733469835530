import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 3;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const parentMenu = {
  position: 3,
  icon: "fas fa-store",
  name: "Store",
};
export const name = "StoreCategory";
export const route = "/store-categories";
export const api = "v1/store-categories";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "feature",
    label: "feature",
    type: "switch",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "feature",
    label: "feature",
    type: "switch",
  },
];
