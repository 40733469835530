import {
  Button,
  Col,
  Drawer,
  Image,
  Pagination,
  Rate,
  Row,
  Spin,
  Tabs,
} from "@pankod/refine-antd";
import { API_URL, imageUrl } from "provider/authProvider";
import { axiosInstance, checkToken, getList } from "provider/dataProvider";
import { createContext, useEffect, useState } from "react";
import { NaverMap, Marker } from "react-naver-maps";
import moment from "moment";
import "./styles.css";
import { DrawerComment } from "components/dashboard/drawerComment";
import { DrawerLike } from "components/dashboard/drawerLike";
import { useNavigation, useTranslate } from "@pankod/refine-core";
export const NaverMapContext = createContext(null);
const { TabPane } = Tabs;
export const DeliveryMap = () => {
  const t = useTranslate();
  const navermaps = window.naver.maps;
  const [mapCenter, setMapCenter] = useState({
    lat: 37.70528,
    lng: 126.617227,
  });
  const { push } = useNavigation();
  const [stores, setStores] = useState([]);
  const [devices, setDevices] = useState([]);
  const [totalDevice, setTotalDevice] = useState(0);
  const [viewStore, setViewStore] = useState(null);
  const [storeDevices, setStoreDevices] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewTotal, setReviewTotal] = useState(0);
  const [reviewPage, setReviewPage] = useState(1);
  const [detailReview, setDetailReview] = useState(null);
  const [detailReviewLike, setDetailReviewLike] = useState(false);
  const [bioAverage, setBioAverage] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deviceOperate, setDeviceOperate] = useState([]);
  const [setTab, setTabValue] = useState("1");

  const getStore = async () => {
    setIsLoading(true);
    checkToken();
    const query = {
      page: 1,
      pageSize: 10,
      where: { name: { contains: search, mode: "insensitive" } },
      joins: ["category"],
    };
    const url = `${API_URL}/v1/stores/location?latitude=${
      mapCenter.lat
    }&longitude=${mapCenter.lng}&radius=10000&${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setStores(data.data);
    setIsLoading(false);
  };

  const getDeviceOperate = async () => {
    checkToken();
    const url = `${API_URL}/v1/device-operates`;
    const { data } = await axiosInstance.get(url);
    setDeviceOperate(data.data);
  };

  const getDevice = async () => {
    setIsLoading(true);
    checkToken();
    const query = {
      page: 1,
      pageSize: 10,
      where: { name: { contains: search, mode: "insensitive" } },
    };
    const url = `${API_URL}/v1/devices/location?latitude=${
      mapCenter.lat
    }&longitude=${mapCenter.lng}&radius=10000&${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setDevices(data.data);
    setTotalDevice(data.totalRecords);
    setIsLoading(false);
  };

  const getSetting = async () => {
    checkToken();
    const url = `${API_URL}/v1/settings`;
    const { data } = await axiosInstance.get(url);
    data.data.forEach((item) => {
      if (item.metaKey === "bio_average") {
        setBioAverage(parseInt(item.metaValue));
      }
    });
  };

  const getStoreInfo = async (storeId) => {
    checkToken();
    const url = `${API_URL}/v1/stores/${storeId}`;
    const { data } = await axiosInstance.get(url);
    setViewStore(data);
  };

  const getStoreReview = async (storeId, page) => {
    checkToken();
    const pagination = {
      pageSize: 5,
      current: page,
    };
    const sorter = [{ field: "createdAt", order: "desc" }];
    const filters = [
      {
        field: "storeId",
        operator: "eq",
        value: storeId,
      },
    ];
    const metaData = {
      joins: ["user"],
    };
    const data = await getList(
      "Review",
      pagination,
      filters,
      [sorter],
      metaData
    );
    if (data) {
      setReviews(data.data);
      setReviewTotal(data.total);
    }
  };

  const getStoreDevice = async (storeId, page) => {
    checkToken();
    const pagination = {
      pageSize: 10,
      current: page,
    };
    const sorter = [{ field: "createdAt", order: "desc" }];
    const filters = [
      {
        field: "storeId",
        operator: "eq",
        value: storeId,
      },
    ];
    const metaData = {};
    const data = await getList(
      "Device",
      pagination,
      filters,
      [sorter],
      metaData
    );
    if (data) {
      setStoreDevices(data.data);
    }
  };

  const onChangePageReview = (page, pageSize) => {
    setReviewPage(page);
    getStoreReview(viewStore, page);
  };

  const handleClickStore = (store) => {
    getStoreInfo(store.id);
    getStoreReview(store.id, 1);
    getStoreDevice(store.id, 1);
    setMapCenter({ lat: store.latitude, lng: store.longitude });
  };

  const handleClickDevice = (device) => {
    getStoreInfo(device.storeId);
    getStoreReview(device.storeId, 1);
    getStoreDevice(device.storeId, 1);
    setMapCenter({ lat: device.latitude, lng: device.longitude });
  };

  const closeStoreDetail = () => {
    setViewStore(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getStore();
      getDevice();
    }
  };

  const handleSearch = () => {
    getStore();
    getDevice();
  };

  const onChangeTab = (tab) => {
    setTabValue(tab);
  };

  const getOperateState = (row) => {
    const operateStateValue = deviceOperate.find(
      (item) => row.value < item.to && row.value >= item.from
    );
    if (!operateStateValue) return <></>;
    return (
      <div className="flex gap-2 items-center">
        <div
          className="circle-operate small"
          style={{ background: operateStateValue.color }}
        ></div>
      </div>
    );
  }

  useEffect(() => {
    getSetting();
    getStore();
    getDevice();
    getDeviceOperate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="p-2">
        <div className="flex border rounded items-start">
          <div className="w-3/12">
            <div className="side-one">
              <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                <div className="flex-grow">
                  <div className="relative w-full">
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                    />
                  </div>
                </div>
                <div className="ml-4">
                  <button
                    onClick={handleSearch}
                    className="flex items-center justify-center bg-primary hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                  >
                    <span>Search</span>
                  </button>
                </div>
              </div>
              <div
                className="row sideBar overflow-auto"
                style={{ maxHeight: "70vh" }}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 text-center">
                      <Spin spinning={isLoading} tip="Loading..." />
                    </div>
                  </div>
                  <div className="mt-2 mb-2 flex w-full text-center"></div>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  onChange={onChangeTab}
                  className="w-full"
                >
                  <TabPane tab={t("crud.menus.Device")} key="1">
                    {devices.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => handleClickDevice(item)}
                        className="row sideBar-body"
                      >
                        <div className="col-sm-2 col-xs-2 sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              className="shadow-md border object-cover"
                              src={
                                item.image
                                  ? imageUrl(item.image)
                                  : "/logo-main.png"
                              }
                              alt={item.name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-10 col-xs-10 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-xs-8 sideBar-name">
                              <span className="name-meta">{item.name}</span>
                            </div>
                            <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                              <span className="time-meta pull-right">
                                {moment(
                                  item.updatedAt ?? item.createdAt
                                ).format("DD/MM/YYYY H:mm")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabPane>
                  <TabPane tab={t("crud.menus.Store")} key="2">
                    {stores.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => handleClickStore(item)}
                        className="row sideBar-body"
                      >
                        <div className="col-sm-2 col-xs-2 sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              className="shadow-md border object-cover"
                              src={
                                item.image
                                  ? imageUrl(item.image)
                                  : "/logo-main.png"
                              }
                              alt={item.name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-10 col-xs-10 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-xs-8 sideBar-name">
                              <span className="name-meta">{item.name}</span>
                            </div>
                            <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                              <span className="time-meta pull-right">
                                {moment(
                                  item.updatedAt ?? item.createdAt
                                ).format("DD/MM/YYYY H:mm")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="w-9/12">
            <NaverMap
              style={{
                width: "100%",
                height: "80vh",
              }}
              defaultZoom={10}
              center={mapCenter}
              mapTypeControl={true}
              mapDataControl={true}
              scaleControl={true}
              zoomControl={true}
            >
              {parseInt(setTab) === 2 &&
                stores.map((store) => (
                  <Marker
                    key={store.id}
                    title={store.name}
                    icon={{
                      content: `<img alt="marker" src="${
                        API_URL + store.category.image
                      }" />`,
                      size: new navermaps.Size(38, 58),
                      anchor: new navermaps.Point(19, 58),
                    }}
                    position={
                      new navermaps.LatLng(store.latitude, store.longitude)
                    }
                    onClick={() => handleClickStore(store)}
                  />
                ))}
              {parseInt(setTab) === 1 &&
                deviceOperate.length > 0 &&
                devices.map((device) => {
                  const operateStateValue = deviceOperate.find(
                    (item) =>
                      device.value < item.to && device.value >= item.from
                  );
                  if (!operateStateValue) return <></>;
                  return (
                    <Marker
                      key={device.id}
                      title={device.name}
                      icon={{
                        content: `<div className="circle-operate"
                        style="border: 1px solid #eaeaea;background: ${operateStateValue.color};width: 50px;border-radius: 50%;height: 50px;"></div>`,
                        size: new navermaps.Size(38, 58),
                        anchor: new navermaps.Point(19, 58),
                      }}
                      onClick={() => handleClickDevice(device)}
                      position={
                        new navermaps.LatLng(device.latitude, device.longitude)
                      }
                    />
                  );
                })}
            </NaverMap>
          </div>
        </div>
        <div className="mt-3 mb-3 flex justify-center space-x-4">
          <div className="shadow bg-nuriflex text-white rounded-xl p-10 flex flex-col items-center justify-center">
            <h3 className="text-white text-base">
              {t("Total devices")}: {totalDevice}
            </h3>
            <h3 className="text-white text-base">{t("Total alarm")}: 5</h3>
            <h3 className="text-white text-base">
              {t("Total cleanliness")}: 5
            </h3>
          </div>
          <div className="shadow bg-nuriflex text-white rounded-xl p-10 flex flex-col items-center justify-center">
            <h3 className="text-white text-base">
              {t("Average of bio figures by region")}: {bioAverage}
            </h3>
          </div>
          <div className="shadow bg-nuriflex text-white rounded-xl p-10 flex flex-col items-center justify-center">
            <h3 className="text-white text-base">
              {t("Average of bio figures by industry")}: {bioAverage}
            </h3>
          </div>
        </div>
      </div>
      <Drawer
        title={viewStore ? viewStore.name : ""}
        placement="right"
        onClose={closeStoreDetail}
        visible={viewStore}
      >
        {viewStore && (
          <>
            <Row gutter={10}>
              <Col span={24}>
                {viewStore.image && (
                  <Image
                    width={"100%"}
                    height={250}
                    src={API_URL + viewStore.image}
                  />
                )}
              </Col>
              <Col span={12}>
                <h2 className="store-name">{viewStore.name}</h2>
                <Rate disabled defaultValue={viewStore.avgReview ?? 0} />
              </Col>
              <Col className="text-right" span={12}>
                {viewStore.totalReview ?? 0} review
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={() => push("/Store/edit/" + viewStore.id)}
                >
                  {t("View store detail")}
                </Button>
              </Col>
              <Col span={24} className="mt-2 mb-2">
                {storeDevices.length > 0 && <h3 className="text-base">
                  {t("crud.fields.devices")}
                </h3>}
                {storeDevices.map((item) => (
                  <Row key={item.id} className="mb-2">
                    <Col span={12}>{item.name}</Col>
                    <Col span={4}>{getOperateState(item)}</Col>
                    <Col span={4}>
                      <Button
                        type="primary"
                        onClick={() => push("/Device/edit/" + item.id)}
                      >
                        {t("View device")}
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col span={24}>{viewStore.description}</Col>
            </Row>
            <div className="row mt-3">
              {reviews.map((item) => (
                <div key={item.id} className="review-block col-12">
                  <div className="row">
                    <div className="col-6 inline-flex items-center">
                      <div className="avatar">
                        {item?.user?.image && (
                          <img src={imageUrl(item.user.image)} alt="" />
                        )}
                      </div>
                      <div className="name ml-2">
                        {item.user.name}
                        <br />
                        {moment(item.createdAt).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="col-6">
                      <Rate disabled defaultValue={item.point ?? 0} />
                    </div>
                    <div className="mt-2 col-12 content">{item.content}</div>
                    <div className="mt-2 col-12 images">
                      {item.images &&
                        item.images.map((image) => (
                          <Image
                            width={100}
                            height={100}
                            src={imageUrl(image)}
                          />
                        ))}
                    </div>
                    <div className="mt-1 col-12 text-right">
                      <span
                        onClick={() => setDetailReview(item.id)}
                        className="cursor-pointer"
                      >
                        {item.commentCount}&nbsp;
                        <i className="fas fa-comment"></i>
                      </span>
                      &nbsp;&nbsp;
                      <span
                        onClick={() => setDetailReviewLike(item.id)}
                        className="cursor-pointer"
                      >
                        {item.reactionCount}&nbsp;
                        <i className="fas fa-heart"></i>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-12">
                {reviewTotal > 0 && (
                  <Pagination
                    defaultCurrent={reviewPage}
                    pageSize={5}
                    total={reviewTotal}
                    onChange={onChangePageReview}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Drawer>
      {detailReview && (
        <DrawerComment reviewId={detailReview} setReviewId={setDetailReview} />
      )}
      {detailReviewLike && (
        <DrawerLike
          reviewId={detailReviewLike}
          setReviewId={setDetailReviewLike}
        />
      )}
    </>
  );
};
