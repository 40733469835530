import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 1;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const parentMenu = {
  position: 1,
  icon: "fas fa-building",
  name: "Bio Avg Value",
};
export const name = "BioAvgValue";
export const route = "/bio-avg-values";
export const api = "v1/bio-avg-values";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["device"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "deviceId",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "device",
    label: "device",
    type: "select",
    select: {
      id: "id",
      label: "name",
    },
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "location",
    label: "location",
    type: "text",
  },
  {
    field: "from",
    label: "fromTime",
    type: "time",
  },
  {
    field: "to",
    label: "toTime",
    type: "time",
  },
  {
    field: "value",
    label: "value",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "location",
    label: "location",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "deviceId",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "from",
    label: "fromTime",
    type: "time",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "to",
    label: "toTime",
    type: "time",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "value",
    label: "value",
    type: "number",
    rules: [
      {
        required: true,
      },
    ],
  },
];
