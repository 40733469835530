import { CrudList, GroupEdit, GroupCreate } from "pages/groups";

export const position = 10;
export const crud = {
  list: () => CrudList,
  create: () => GroupCreate,
  edit: () => GroupEdit,
};
export const name = "SystemUser";
export const parentMenu = {
  position: 9,
  icon: "fas fa-users-cog",
  name: "User & Permission",
};
export const route = "/users";
export const api = "v1/users";
export const listFilters = [
  {
    field: "groupId",
    operator: "nin",
    value: [1, 2],
  },
];
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["group"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "groupId",
    label: "group",
    type: "select",
    resource: "groups",
    select: {
      id: "id",
      label: "name",
      where: {
        id: {
          notIn: [1, 2]
        }
      },
    }
  },
  {
    field: "email",
    label: "email",
    type: "text",
    align: "left",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];
export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "group",
    label: "group",
    type: "select",
    select: {
      id: "id",
      label: "name",
    },
  },
  {
    field: "email",
    label: "email",
    type: "text",
    align: "left",
  },
  {
    field: "image",
    label: "image",
    type: "image",
    align: "center",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "createdAt",
    label: "createdAt",
    type: "date",
    align: "center",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "groupId",
    label: "group",
    type: "select",
    resource: "groups",
    select: {
      id: "id",
      label: "name",
      where: {
        id: {
          notIn: [1, 2]
        }
      },
    },
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "username",
    label: "username",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "email",
    label: "email",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "password",
    label: "password",
    type: "text",
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
];
