import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 6;
export const crud = {
  icon: "fas fa-atlas",
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Manual";
export const route = "/manuals";
export const api = "v1/manuals";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["device"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "deviceId",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "device",
    label: "device",
    type: "select",
    select: {
      id: "id",
      label: "name",
    },
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "deviceId",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "title",
    label: "title",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "content",
    label: "content",
    type: "text-editor",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "pdf",
    label: "pdf",
    type: "pdf",
  },
];
