import { AuthProvider } from "@pankod/refine-core";
import { axiosInstance } from "provider/dataProvider";
import { handleError } from "helper";
import jwt_decode from "jwt-decode";

export const TOKEN_KEY = "refine-auth";
export const TOKEN_REFRESH_KEY = "refresh-auth";
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const imageUrl = (image: string) => `${API_URL}${image}`;

const checkToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    throw new Error("Token not found");
  }
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const refreshToken = async () => {
  const token = localStorage.getItem(TOKEN_REFRESH_KEY);
  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_REFRESH_KEY);
    return false;
  }
  checkToken();
  const url = `${API_URL}/v1/auth/refresh-token`;
  const { data } = await axiosInstance.get(url);
  if (data.token) {
    localStorage.setItem(TOKEN_KEY, data.token);
    localStorage.setItem(TOKEN_REFRESH_KEY, data.refreshToken);
    return true;
  }
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_REFRESH_KEY);
  // eslint-disable-next-line no-restricted-globals
  location.href = "/login";
  return false;
};

const getUserInfo: any = async () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return false;
    }
    checkToken();
    const url = `${API_URL}/v1/auth/me`;
    const { data } = await axiosInstance.get(url);
    if (data) {
      return data;
    }
    
    return false;
  } catch (error: any) {
    console.log(error);
    console.log(error.statusCode);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_REFRESH_KEY);
    // eslint-disable-next-line no-restricted-globals
    location.href = "/";
    if (error.statusCode === 401) {
      const refreshData = await refreshToken();
      if (refreshData) {
        return await getUserInfo();
      }
    }
  }
};

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const url = `${API_URL}/v1/auth/login`;
    try {
      const { data } = await axiosInstance.post(url, {
        email: username,
        password,
      });
      if (!data) {
        return Promise.reject(new Error("Login error"));
      }
      const decoded: any = jwt_decode(data.token);
      if ([1, 2].includes(decoded.groupId)) {
        return Promise.reject(
          new Error("This user don't have access to this application")
        );
      }
      localStorage.setItem(TOKEN_KEY, data.token);
      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject(
        new Error(handleError(error.message, error?.response?.data))
      );
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_REFRESH_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    checkToken();
    const decoded: any = jwt_decode(token);
    if ([1, 2].includes(decoded.groupId)) {
      return Promise.reject();
    }
    const url = `${API_URL}/v1/groups/${decoded.groupId}`;
    const { data } = await axiosInstance.get(url);
    if (!data) {
      return Promise.reject();
    }
    return Promise.resolve(data);
  },
  getUserIdentity: async () => {
    const user = await getUserInfo();
    if (!user) {
      return Promise.reject();
    }
    return Promise.resolve(user);
  },
};
