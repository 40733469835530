import {
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Select,
  TimePicker,
} from "@pankod/refine-antd";
import moment from "moment";
import { Markdown } from "./fields/Markdown";
import { PdfUploader } from "./fields/PdfUploader";
import { SelectItem } from "./fields/Select";

import { Uploader } from "./fields/Uploader";
import { UploaderMultiple } from "./fields/UploaderMultiple";

const { RangePicker } = DatePicker;

export const CrudField: React.FC<any> = (props) => {

  switch (props.type) {
    case "text":
      return <Input {...props} />;
    case "number":
      return <InputNumber {...props} />;
    case "switch":
      return <Switch {...props} />;
    case "hidden":
      return <Input {...props} disabled={true} type="hidden" />;
    case "text-editor":
      return <Markdown {...props} />;
    case "textarea":
      return <Input.TextArea {...props} />;
    case "image":
      return <Uploader {...props} />;
    case "pdf":
      return <PdfUploader {...props} />;
    case "images":
      return <UploaderMultiple {...props} />;
    case "select-option":
      return (
        <Select
          filterOption={false}
          allowClear={true}
          showSearch={true}
          className="w-full"
          value={props.value}
          onChange={(value) => props.onChange(value)}
        >
          {props.options &&
            props.options.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
        </Select>
      );
    case "select":
      return <SelectItem {...props} />;
    case "select_multiple":
      return <SelectItem {...props} multiple={true} />;
    case "date-time":
      let value = moment();
      if (props.value) {
        value = moment(props.value);
      }
      return <DatePicker {...props} value={value} />;
    case "time":
      let valueTime: any = moment();
      if (props.value) {
        valueTime = moment(props.value);
      }
      return <TimePicker {...props} value={valueTime} />;
    case "date-range":
      return <RangePicker {...props} />;
    default:
      return <Input {...props} />;
  }
};
