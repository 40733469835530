import "./style.less";

import { useLogin, useTranslate } from "@pankod/refine-core";

import { AntdLayout, Button, Card, Checkbox, Col, Form, Input, Row, Typography } from "@pankod/refine-antd";

import { Trans } from "react-i18next";
import { validateMessages } from "provider/validateMessage";

const { Text, Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const t = useTranslate();

  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} className="layout-title">
      <Trans
        i18nKey="pages.login.message"
        defaults={t("pages.login.sign-in")}
        components={[<Text key="0" style={{ color: "#13489d" }} />]}
      />
    </Title>
  );

  return (
    <AntdLayout>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={{ maxWidth: "408px", margin: "auto" }}>
            <img
              style={{ marginBottom: 46, margin: "0 auto", display: "block" }}
              src="/logo-main.png"
              width="150"
              alt="logo"
            /><br /><br />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }} className="shadow">
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                validateMessages={validateMessages}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  username: "admin",
                  password: "admin",
                }}
              >
                <Form.Item
                  name="username"
                  label={t("pages.login.email", "Email")}
                  rules={[{ required: true }]}
                >
                  <Input type="email" size="large" placeholder="" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("pages.login.password", "Password")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="" size="large" />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {t("pages.login.remember", "Remember me")}
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button type="primary" size="large" htmlType="submit" block>
                  {t("pages.login.sign-in", "Sign in")}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
