import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 4;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Store";
export const parentMenu = {
  position: 3,
  icon: "fas fa-store",
  name: "Store",
};
export const route = "/stores";
export const api = "v1/stores";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["category", "company"];
export const listIncludes = { _count: { select: { devices: true } } };
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "categoryId",
    label: "category",
    type: "select",
    resource: "store-categories",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "companyId",
    label: "company",
    type: "select",
    resource: "companies",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "category",
    sort: "categoryId",
    label: "category",
    type: "select",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "company",
    sort: "companyId",
    label: "company",
    type: "select",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "_count.devices",
    label: "devices",
    type: "text_array",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "categoryId",
    label: "category",
    type: "select",
    resource: "store-categories",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "companyId",
    label: "company",
    type: "select",
    resource: "companies",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "latitude",
    label: "latitude",
    type: "number",
  },
  {
    field: "longitude",
    label: "longitude",
    type: "number",
  },
  {
    field: "description",
    label: "description",
    type: "textarea",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "level",
    label: "level",
    type: "number",
  },
  {
    field: "view",
    label: "view",
    type: "number",
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
];
