import { useGetIdentity } from "@pankod/refine-core";

import "./styles.css";
import { API_URL, imageUrl } from "provider/authProvider";
import { axiosInstance, checkToken, getList } from "provider/dataProvider";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import socketClient from "provider/socketClient";
import { Image, Pagination, Spin } from "@pankod/refine-antd";

export const ListMessenger: React.FC = () => {
  const { data: userIdentity } = useGetIdentity<any>();
  const [chats, setChats] = useState<any[]>([]);
  const [chatTotal, setChatTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [chat, setChat] = useState<any>();
  const [messages, setMessages] = useState<any[]>([]);
  const [messageTotal, setMessageTotal] = useState(0);
  const [messagePage, setMessagePage] = useState(1);
  const [roomSecretToken, setRoomSecretToken] = useState("");
  const [message, setMessage] = useState("");
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const getData = async (page: number) => {
    setIsLoadingChat(true);
    checkToken();
    const pagination = {
      pageSize: 10,
      current: page,
    };
    const sorter = [
      { field: "updatedAt", order: "desc" },
      { field: "createdAt", order: "desc" },
    ];
    // const filters = [
    //   {
    //     field: "withUserId",
    //     operator: "eq",
    //     value: userIdentity.id,
    //   },
    // ];
    const filters = [];
    if (search) {
      filters.push({
        field: "OR",
        operator: "eq",
        value: [
          {
            title: {
              contains: search,
              mode: "insensitive",
            },
          },
          {
            user: {
              name: { contains: search, mode: "insensitive" },
              email: { contains: search, mode: "insensitive" },
            },
          },
        ],
      });
    }
    const metaData = {
      joins: ["user"],
    };
    const data = await getList("Chat", pagination, filters, sorter, metaData);
    setIsLoadingChat(false);
    return data;
  };

  const getChatData = async () => {
    if (!userIdentity) return;
    const data = await getData(1);
    setChats(data.data);
    setChatTotal(data.total);
  };

  const onChangeChatPage = async (page: number) => {
    if (!userIdentity) return;
    setPage(page);
    const data = await getData(page);
    setChats(data.data);
    setChatTotal(data.total);
  };

  const handleMessage = () => {
    if (!socketClient) return;
    socketClient?.off("receiveMessage").on(`receiveMessage`, (data) => {
      if (!data) return;
      setMessages((value) => [...value, data]);
      scrollToBottom();
    });
  };

  const getMessage = (roomSecretToken: string) => {
    const page = {
      roomSecretToken,
      page: messagePage,
      limit: 10,
    };
    setIsLoadingMessage(true);
    socketClient.emit("getMessages", { page }, (data: any) => {
      setIsLoadingMessage(false);
      if (!data.messages) return;
      setMessages(data.messages);
      setMessageTotal(data.total);
      scrollToBottom();
    });
  };

  const getOldMessage = () => {
    const page = {
      roomSecretToken,
      page: messagePage + 1,
      limit: 10,
    };
    setMessagePage(messagePage + 1);
    setIsLoadingMessage(true);
    socketClient.emit("getMessages", { page }, (data: any) => {
      setIsLoadingMessage(false);
      if (!data.messages) return;
      setMessages([...data.messages, ...messages]);
      setMessageTotal(data.total);
    });
  };

  const onSendMessage = (image = "") => {
    const data = {
      message: message,
      image,
      chatId: chat.id,
    };
    socketClient.emit("sendMessage", { data }, (data: any) => {
      if (!data) return;
      setMessage("");
    });
  };

  const scrollToBottom = () => {
    if (!messagesEndRef?.current) return;
    messagesEndRef?.current.scrollIntoView({ block: "end" });
  };

  const triggerUpload = () => {
    document.getElementById("myImage")?.click();
  };

  const onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      fmData.append("file", file);
      try {
        checkToken();
        const { data } = await axiosInstance.post(
          `${API_URL}/v1/auth/upload`,
          fmData,
          config
        );
        onSendMessage(data.data.url);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getChatData();
    }
  };

  useEffect(() => {
    if (!userIdentity) return;
    getChatData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdentity]);

  useEffect(() => {
    if (!chat) return;
    setMessagePage(1);
    socketClient.emit("joinRoom", { chatId: chat.id }, (data: any) => {
      if (!data.roomSecretToken || data.roomSecretToken === "null") return;
      setRoomSecretToken(data.roomSecretToken);
      getMessage(data.roomSecretToken);
      handleMessage();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  return (
    <div className="container app shadow-md">
      <div className="row app-one">
        <div className="col-sm-4 side">
          <div className="side-one">
            <div className="row heading">
              <div className="col-sm-2 col-xs-2 heading-avatar">
                <div className="heading-avatar-icon">
                  {userIdentity && (
                    <img
                      className="shadow-md border object-cover"
                      src={imageUrl(userIdentity.image)}
                      alt={userIdentity.email}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-10 col-xs-10 sideBar-main">
                <div className="row">
                  {userIdentity && (
                    <div className="col-sm-8 col-xs-8 sideBar-name">
                      <span className="name-meta">{userIdentity.name}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
              <div className="flex-grow">
                <div className="relative w-full">
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                    className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  />
                </div>
              </div>
              <div className="ml-4">
                <button
                  onClick={getChatData}
                  className="flex items-center justify-center bg-primary hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                >
                  <span>Search</span>
                </button>
              </div>
            </div>
            <div className="row sideBar">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-center">
                    <Spin spinning={isLoadingChat} tip="Loading..." />
                  </div>
                </div>
                {chats.map((item: any) => (
                  <div
                    key={item.id}
                    onClick={() => setChat(item)}
                    className="row sideBar-body"
                  >
                    <div className="col-sm-2 col-xs-2 sideBar-avatar">
                      <div className="avatar-icon">
                        <img
                          className="shadow-md border object-cover"
                          src={
                            item.user.image
                              ? imageUrl(item.user.image)
                              : "/logo-main.png"
                          }
                          alt={item.user.email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-10 col-xs-10 sideBar-main">
                      <div className="row">
                        <div className="col-sm-8 col-xs-8 sideBar-name">
                          <span className="name-meta">{item.title}</span>
                          <br />
                          <span className="name-meta">{item.user.name}</span>
                        </div>
                        <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                          <span className="time-meta pull-right">
                            {moment(item.updatedAt ?? item.createdAt).format(
                              "DD/MM/YYYY H:mm"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mt-2 mb-2 col-12 text-center">
                  <Pagination
                    pageSize={10}
                    current={page}
                    total={chatTotal}
                    onChange={onChangeChatPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-8 conversation">
          <div className="row heading">
            <div className="col-sm-2 col-md-1 col-xs-2 heading-avatar">
              <div className="heading-avatar-icon">
                {chat?.user && (
                  <img
                    className="shadow-md border object-cover"
                    src={
                      chat.user.image
                        ? imageUrl(chat.user.image)
                        : "/logo-main.png"
                    }
                    alt={chat.user.email}
                  />
                )}
              </div>
            </div>
            {chat && (
              <div className="col-sm-8 col-xs-8 heading-name">
                <div className="heading-name-meta">{chat.user.name}</div>
                <span className="heading-online">{chat.user.email}</span>
              </div>
            )}
          </div>
          <div className="row message" id="conversation">
            <div className="col-12" ref={messagesEndRef} id="chat-message">
              <div className="row message-previous">
                <div className="col-sm-12 previous">
                  {messageTotal > messages.length && (
                    <button
                      onClick={getOldMessage}
                      className="btn btn-primary btn-sm"
                    >
                      Show Previous Message!
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <Spin spinning={isLoadingMessage} tip="Loading..." />
                </div>
              </div>
              {messages.map((item: any) => (
                <div key={item.id} className="row message-body">
                  <div
                    className={
                      "col-sm-12 " +
                      (item.userId === chat.userId
                        ? "message-main-receiver"
                        : "message-main-sender")
                    }
                  >
                    <div
                      className={
                        item.userId === chat.userId
                          ? "receiver shadow-md"
                          : "sender shadow-md"
                      }
                    >
                      <div className="message-text">{item.message}</div>
                      {item.image && (
                        <div className="message-image">
                          <Image height={200} src={imageUrl(item.image)} />
                        </div>
                      )}
                      <span className="message-time pull-right">
                        {moment(item.createdAt).fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
            <div>
              <button
                onClick={triggerUpload}
                className="flex items-center justify-center text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  />
                </svg>
              </button>
              <input
                type="file"
                id="myImage"
                className="image-input"
                onChange={onImageChange}
              />
            </div>
            <div className="flex-grow ml-4">
              <div className="relative w-full">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                />
              </div>
            </div>
            <div className="ml-4">
              <button
                onClick={() => onSendMessage()}
                className="flex items-center justify-center bg-primary hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
              >
                <span>Send</span>
                <span className="ml-2">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
