import {
  Alert,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "../cruds/field";
import { getResource } from "resources";
import { useParams } from "react-router-dom";
import { validateMessages } from "provider/validateMessage";

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const Profile: React.FC<UpdateProps> = ({ onFinish = false }) => {
  const t = useTranslate();
  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();

  const params = useParams();
  const formFields: any[] = [
    {
      field: "id",
      label: "ID",
      type: "hidden",
    },
    {
      field: "name",
      label: "name",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "email",
      label: "email",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "password",
      label: "password",
      type: "text",
    },
    {
      field: "image",
      label: "image",
      type: "image",
    },
    {
      field: "phone",
      label: "phone",
      type: "text",
    },
  ];
  
  const { formProps, saveButtonProps, queryResult, setId } = useForm<any>({
    redirect: false,
    action: "edit",
    resource: "SystemUser",
  });

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  useEffect(() => {
    if (params.id) {
      setId(parseInt(params.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="hide-header">
      <Edit saveButtonProps={saveButtonProps}>
        {deprecated === "deleted" && (
          <Alert
            message="This data is deleted."
            type="warning"
            style={{ marginBottom: 20 }}
            action={<ListButton size="small" />}
          />
        )}
        {deprecated === "updated" && (
          <Alert
            message="This data is updated. Refresh to see changes."
            type="warning"
            style={{ marginBottom: 20 }}
            action={<RefreshButton size="small" onClick={handleRefresh} />}
          />
        )}
        <Form
          {...formProps}
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={(values) => {
            if (onFinish) return onFinish(values, formProps);
            return formProps.onFinish && formProps.onFinish(values);
          }}
        >
          <div className="flex w-full gap-5">
            <div className="w-4/12">
              {formFields.map((item) => (
                <Form.Item
                  key={item.field}
                  label={t(`crud.fields.${item.label}`)}
                  name={item.field}
                  rules={item.rules}
                  valuePropName={item.type === "switch" ? "checked" : "value"}
                  hidden={item.type === "hidden"}
                >
                  <CrudField
                    type={item.type}
                    resource={item.resource}
                    select={item.select}
                  />
                </Form.Item>
              ))}
            </div>
          </div>
        </Form>
      </Edit>
    </div>
  );
};
