import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message, Modal } from "@pankod/refine-antd";
import { useEffect, useState } from "react";

import { API_URL } from "provider/authProvider";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { useTranslate } from "@pankod/refine-core";

export const UploaderMultiple: React.FC<any> = (props) => {
  const [previewImage, setPreviewImage] = useState<any>({
    previewImage: false,
    previewVisible: false,
    previewTitle: "",
  });
  const [fileList, setFileList] = useState<any>([]);
  const [imageData, setImageData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate();

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleCancel = () => {
    setPreviewImage({
      previewImage: false,
      previewVisible: false,
      previewTitle: "",
    });
  };

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      checkToken();
      const { data } = await axiosInstance.post(
        `${API_URL}/v1/auth/upload`,
        fmData,
        config
      );
      const file = {
        uid: new Date().getTime(),
        url: data.data.url,
        thumbUrl: `${API_URL}${data.data.url}`,
        status: "done",
      };
      setFileList([...fileList, file]);
      setImageData([...imageData, data.data.url]);
      props.onChange([...imageData, data.data.url]);
      setIsLoading(false);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "removed") {
      setFileList(fileList.filter((item: any) => item.url !== info.file.url));
      setImageData(imageData.filter((item: string) => item !== info.file.url));
      return;
    }
    setIsLoading(false);
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('crud.fields.upload')}</div>
    </div>
  );

  useEffect(() => {
    if (!props.value) {
      setImageData([]);
      setFileList([]);
      props.onChange([]);
      return;
    }
    setImageData(props.value);
    const temp: any = [];
    let count = 0;
    props.value.forEach((item: string) => {
      temp.push({
        uid: count,
        url: item,
        thumbUrl: `${API_URL}${item}`,
        status: "done",
      });
      count++;
    });
    setFileList(temp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <>
      <Upload
        name="image"
        accept="image/*"
        multiple={true}
        listType="picture-card"
        fileList={fileList}
        className="avatar-uploader"
        customRequest={uploadImage}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onPreview={handlePreview}
      >
        {uploadButton}
      </Upload>
      <Modal
        visible={previewImage.previewVisible}
        title={previewImage.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImage.previewImage}
        />
      </Modal>
    </>
  );
};
