import { CrudOperators, CrudSorting } from "@pankod/refine-core";
import {
  DataProvider,
  HttpError,
  CrudFilters as RefineCrudFilter,
} from "@pankod/refine-core";
import axios, { AxiosInstance } from "axios";

import { TOKEN_KEY, API_URL } from "./authProvider";
import { getResourceApi } from "../resources";
import { handleError } from "helper";
import { stringify } from "query-string";

type CrudQuery = {
  where?: any;
  joins?: string[];
  includes?: any;
  select?: { only?: string[]; except?: string[] };
  orderBy?: any[];
  page?: number;
  pageSize?: number;
};

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: handleError(error.message, error?.response?.data),
      statusCode: error.response?.status,
    };
    return Promise.reject(customError);
  }
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
      return "not";
    case "nin":
      return "notIn";
    case "in":
      return "in";
    case "ncontains":
      return "not";
    case "containss":
      return "contains";
    case "ncontainss":
      return "not";
    case "null":
      return "equals";
    case "or":
      return "OR";
  }

  return operator;
};

const generateSort = (sort?: CrudSorting): any => {
  if (!sort || sort.length === 0) return;
  const multipleSort: any = [];
  sort.forEach(({ field, order }) => {
    if (field && order) {
      multipleSort.push({ [field]: order });
    }
  });
  return multipleSort;
};

const generateFilter = (filters?: RefineCrudFilter): any => {
  const crudFilters: any = {};
  if (!filters) return {};
  filters.forEach((filter: any) => {
    if (filter.operator === "eq") {
      crudFilters[filter.field] = filter.value;
      return;
    }
    if (filter.field) {
      if (filter.operator === "null") return;
      crudFilters[filter.field] = {
        [mapOperator(filter.operator)]: filter.value,
      };
      if (filter.operator === "containss") {
        crudFilters[filter.field].mode = "insensitive";
      }
    }
  });

  return crudFilters;
};

export const checkToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return Promise.reject();
  }
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const getList = async (
  resource: any,
  pagination: any,
  filters: any,
  sort: any,
  metaData: any = {}
) => {
  checkToken();
  const url = `${API_URL}/${getResourceApi(resource)}`;
  const current = pagination?.current || 1;
  const pageSize = pagination?.pageSize || 10;
  const generatedFilters = generateFilter(filters);
  const sortBy = generateSort(sort);
  const query: CrudQuery = {
    where: generatedFilters,
    orderBy: sortBy ? sortBy : [],
    page: current,
    pageSize: pageSize,
  };
  if (metaData?.joins) {
    query.joins = metaData.joins;
  }
  if (metaData?.includes) {
    query.includes = metaData.includes;
  }
  const crudQuery = new URLSearchParams({ crudQuery: JSON.stringify(query) });
  const { data } = await axiosInstance.get(`${url}?${crudQuery}`);

  return {
    data: data.data,
    total: data.totalRecords,
  };
};

const NestProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort, metaData = {} }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}`;
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;
    const generatedFilters = generateFilter(filters);
    const sortBy = generateSort(sort);
    const query: CrudQuery = {
      where: generatedFilters,
      orderBy: sortBy ? sortBy : [],
      page: current,
      pageSize: pageSize,
    };
    if (metaData?.joins) {
      query.joins = metaData.joins;
    }
    if (metaData?.includes) {
      query.includes = metaData.includes;
    }
    const crudQuery = new URLSearchParams({ crudQuery: JSON.stringify(query) });
    const { data } = await httpClient.get(`${url}?${crudQuery}`);

    return {
      data: data.data,
      total: data.totalRecords,
    };
  },

  getMany: async ({ resource, ids }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}`;
    const query: CrudQuery = {
      where: {
        id: {
          in: ids,
        },
      },
    };
    const crudQuery = new URLSearchParams({ crudQuery: JSON.stringify(query) });
    const { data } = await httpClient.get(`${url}?${crudQuery}`);

    return { data };
  },

  create: async ({ resource, variables }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}`;

    const { data } = await httpClient.post(url, variables);

    return { data };
  },

  update: async ({ resource, id, variables }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}/${id}`;
    const { data } = await httpClient.patch(url, variables);

    return { data };
  },

  updateMany: async ({ resource, ids, variables }) => {
    checkToken();
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${getResourceApi(resource)}/${id}`,
          variables
        );
        return data;
      })
    );

    return { data: response };
  },

  createMany: async ({ resource, variables }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}/bulk`;

    const { data } = await httpClient.post(url, { bulk: variables });

    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    checkToken();
    const url = `${apiUrl}/${getResourceApi(resource)}/${id}`;
    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    checkToken();
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${getResourceApi(resource)}/${id}`
        );
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    checkToken();
    const generatedFilters = generateFilter(filters);
    const sortBy = generateSort(sort);
    const queryData: CrudQuery = {
      where: generatedFilters,
      orderBy: sortBy ? sortBy : [],
    };
    const crudQuery = new URLSearchParams({
      crudQuery: JSON.stringify(queryData),
    });
    let requestUrl = `${url}?${crudQuery}`;
    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default NestProvider;
