import {
  Alert,
  Button,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useOne,
  useTranslate,
} from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "../cruds/field";
import { validateMessages } from "provider/validateMessage";
import { Column } from "@ant-design/plots";
import { ColumnConfig } from "@ant-design/charts";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { API_URL } from "provider/authProvider";
import moment from "moment";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const CrudEdit: React.FC<UpdateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const { push } = useNavigation();
  const t = useTranslate();
  const [selectDate, setSelectDate] = useState<any>([moment(), moment()]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [weekData, setWeekData] = useState<any>({});
  const [monthData, setMonthData] = useState<any>({});
  const [yearData, setYearData] = useState<any>({});
  const [avgToday, setAvgToday] = useState<any>(0);

  const config: ColumnConfig = {
    data: chartData,
    xField: "date",
    yField: "value",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "AAA",
      },
      sales: {
        alias: "BBB",
      },
    },
  };

  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });
  const [bioAverage, setBioAverage] = useState(0);

  const getSetting = async () => {
    checkToken();
    const url = `${API_URL}/v1/settings`;
    const { data } = await axiosInstance.get(url);
    data.data.forEach((item: any) => {
      if (item.metaKey === "bio_average") {
        setBioAverage(parseInt(item.metaValue));
      }
    });
  };

  const { data: storeData, refetch: fetchStore } = useOne<any>({
    id: queryResult?.data?.data?.storeId ?? false,
    resource: "Store",
    queryOptions: {
      staleTime: Infinity,
      enabled: false,
    },
  });

  const { data: companyData, refetch: fetchCompany } = useOne<any>({
    id: storeData?.data?.companyId ?? false,
    resource: "Company",
    queryOptions: {
      staleTime: Infinity,
      enabled: false,
    },
  });

  const formFields: any[] = [
    {
      field: "name",
      label: "name",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "serial",
      label: "serial",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "status",
      label: "used",
      type: "switch",
    },
    {
      field: "enabled",
      label: "enabled",
      type: "switch",
    },
    {
      field: "mapShow",
      label: "mapShow",
      type: "switch",
    },
    {
      field: "receiveAlarm",
      label: "receiveAlarm",
      type: "switch",
    },
    {
      field: "alarmAt",
      label: "alarmAt",
      type: "date-time",
    },
    {
      field: "images",
      label: "images",
      type: "images",
    },
    {
      field: "contractId",
      label: "contractId",
      type: "text",
    },
    {
      field: "contractInfo",
      label: "contractInfo",
      type: "textarea",
    },
  ];

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  const getBioAndAlarm = async () => {
    checkToken();
    const url = `${API_URL}/v1/devices/static/${queryResult?.data?.data?.id}`;
    const startDate = selectDate[0] ? selectDate[0].format("YYYY-MM-DD") : "";
    const endDate = selectDate[1] ? selectDate[1].format("YYYY-MM-DD") : "";
    const { data } = await axiosInstance.get(url + `?type=day&startDate=${startDate}&endDate=${endDate}`);
    const bioData = data.value.map((item: any) => {
      return {
        ...item,
        value: parseFloat(item.total.toFixed(2)),
        timestamp: moment(item.data).unix(),
        date: moment(item.data).format('YYYY-MM-DD'),
      } 
    });
    setChartData(bioData.sort((a: any, b: any) => a.timestamp - b.timestamp));
    if (selectDate && selectDate.isSame(moment(), 'day')) {
      setAvgToday(bioData.reduce((a: any, b: any) => a + b.value, 0) / bioData.length);
    }
  };

  const getBioAndAlarmByType = async (type: moment.unitOfTime.StartOf) => {
    checkToken();
    const url = `${API_URL}/v1/devices/static/${queryResult?.data?.data?.id}`;
    const startDate = moment().startOf(type).format('YYYY-MM-DD');
    const endDate = moment().endOf(type).format('YYYY-MM-DD');
    const { data } = await axiosInstance.get(url + `?type=${type}&startDate=${startDate}&endDate=${endDate}`);
    const result = {
      alarm: 0,
      bio: 0,
    };
    if (data.alarm && data.alarm.length > 0) {
      result.alarm = data.alarm[0].count.toFixed(2);
    }
    if (data.value && data.value.length > 0) {
      result.bio = data.value[0].total.toFixed(2);
    }
    switch (type) {
      case "week":
        setWeekData(result);
        break;
      case "month":
        setMonthData(result);
        break;
      case "year":
        setYearData(result);
        break;
    
      default:
        break;
    }
  };

  useEffect(() => {
    if (queryResult?.data?.data?.storeId && !storeData) {
      fetchStore(); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult]);

  useEffect(() => {
    if (storeData?.data?.companyId && !companyData) {
      fetchCompany();
      getBioAndAlarmByType("week");
      getBioAndAlarmByType("month");
      getBioAndAlarmByType("year");
      getBioAndAlarm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    if (!queryResult?.data?.data?.id) return;
    getBioAndAlarm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  return (
    <>
      <h3 className="text-lg font-bold">{t("Fixed information")}</h3>
      <div className="flex w-full space-4 gap-4">
        <div
          className="w-5/12 p-2 shadow rounded p-2 text-base text-white"
          style={{ background: "#585858", color: "#ffffff" }}
        >
          <h4 className="text-white text-lg">
            {t("Device number")}: {formProps.form?.getFieldValue("id")}
          </h4>
          <h4 className="text-white text-lg">
            {t("Current figure")}: {formProps.form?.getFieldValue("value")}
          </h4>
          <h4 className="text-white text-lg">
            {t("Operation Status")}:{" "}
            {formProps.form?.getFieldValue("value") > bioAverage
              ? "High than Average"
              : "Normal"}
          </h4>
          <h4 className="text-white text-lg">
            {t("Contract Address")}: {companyData?.data?.address}
          </h4>
          <h4 className="text-white text-lg">
            {t("Last Inspection Date")}:{" "}
            {formProps.form?.getFieldValue("updatedAt")
              ? moment(formProps.form?.getFieldValue("updatedAt")).format(
                  "DD/MM/YYYY HH:mm"
                )
              : ""}
          </h4>
        </div>
        <div
          className="w-5/12 p-2 shadow rounded p-2 text-base text-white"
          style={{ background: "#7b7b7b", color: "#ffffff" }}
        >
          <h4 className="text-white text-lg">
            {t("Sensor linkage")}: {formProps.form?.getFieldValue("endpoint")}
          </h4>
          <h4 className="text-white text-lg">
            {t("Contract company name")}: {companyData?.data?.name}
          </h4>
          <h4 className="text-white text-lg">
            {t("Business phone number")}: {companyData?.data?.phone}
          </h4>
          <h4 className="text-white text-lg">
            {t("Business email")}: {companyData?.data?.email}
          </h4>
        </div>
        <div className="w-2/12 px-4">
          <Button
            className="py-4"
            style={{height: "initial"}}
            onClick={() =>
              push("/Company/edit/" + companyData?.data?.id)
            }
            type="primary"
          >
            {t("View business details")}
          </Button>
        </div>
      </div>
      <h3 className="text-lg font-bold mt-3">{t("Editable information")}</h3>
      <div className="flex w-full space-4 gap-4">
        <div className="w-5/12">
          <Edit saveButtonProps={saveButtonProps}>
            {deprecated === "deleted" && (
              <Alert
                message="This data is deleted."
                type="warning"
                style={{ marginBottom: 20 }}
                action={<ListButton size="small" />}
              />
            )}
            {deprecated === "updated" && (
              <Alert
                message="This data is updated. Refresh to see changes."
                type="warning"
                style={{ marginBottom: 20 }}
                action={<RefreshButton size="small" onClick={handleRefresh} />}
              />
            )}
            <Form
              {...formProps}
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={(values) => {
                if (onFinish) return onFinish(values, formProps);
                return formProps.onFinish && formProps.onFinish(values);
              }}
            >
              {formFields.map((item) => (
                <Form.Item
                  key={item.field}
                  label={t(`crud.fields.${item.label}`)}
                  name={item.field}
                  rules={item.rules}
                  valuePropName={item.type === "switch" ? "checked" : "value"}
                  hidden={item.type === "hidden"}
                >
                  <CrudField
                    type={item.type}
                    resource={item?.resource}
                    select={item?.select}
                  />
                </Form.Item>
              ))}
              {children}
            </Form>
          </Edit>
        </div>
        <div className="w-5/12 bg-white items-center flex flex-col p-4 shadow rounded">
          <RangePicker value={selectDate} onChange={(e) => setSelectDate(e)} className="mb-3" /><br />
          <Column {...config} className="w-full" />
        </div>
        <div className="w-2/12 bg-white p-2 shadow rounded p-2 text-base text-white">
          <h4 className="text-lg">{t("Number of alarms per week")}: {weekData?.alarm ?? 0}</h4>
          <h4 className="text-lg">{t("Number of alarms per month")}: {monthData?.alarm ?? 0}</h4>
          <h4 className="text-lg">{t("Number of alarms per year")}: {yearData?.alarm ?? 0}</h4>
          <h4 className="text-lg">{t("Average monthly bio count")}: {monthData?.bio ?? 0}</h4>
          <h4 className="text-lg">{t("Average daily bio count")}: {avgToday}</h4>
          <h4 className="text-lg">{t("Time Zones of Main Weeks")}:</h4>
        </div>
      </div>
    </>
  );
};
