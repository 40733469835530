import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 12;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const parentMenu = {
  position: 12,
  icon: "fas fa-plug",
  name: "System",
};
export const name = "Module";
export const route = "/modules";
export const api = "v1/modules";
export const allowExport = true;
export const allowImport = false;
export const primaryId = "id";
export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "code",
    label: "code",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "code",
    label: "code",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
];
