import { CrudCreate } from "pages/cruds";
import { CrudEdit } from "pages/device/edit";
import { CrudList } from "pages/device/list";

export const position = 5;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const parentMenu = {
  position: 5,
  icon: "fab fa-accusoft",
  name: "Device",
};
export const name = "Device";
export const route = "/devices";
export const api = "v1/devices";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["store", 'room', 'user', 'type'];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "storeId",
    label: "store",
    type: "select",
    resource: "stores",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
    }
  },
  {
    field: "typeId",
    label: "deviceType",
    type: "select",
    resource: "device-types",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "model",
    label: "model",
    type: "text",
  },
  {
    field: "serial",
    label: "serial",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "serial",
    label: "serial",
    type: "text",
  },
  {
    field: "serial",
    label: "QRCode",
    type: "qrcode",
  },
  {
    field: "store",
    label: "store",
    type: "select",
    resource: "stores",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "user",
    label: "setupUser",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "value",
    label: "value",
    type: "text",
  },
  {
    field: "value",
    label: "operateStateText",
    type: "operateState",
  },
  {
    field: "status",
    label: "used",
    type: "switch",
  },
  {
    field: "enabled",
    label: "enabled",
    type: "switch",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "storeId",
    label: "store",
    type: "select",
    resource: "stores",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "roomId",
    label: "room",
    type: "select",
    resource: "rooms",
    select: {
      id: "id",
      label: "title",
    }
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
    }
  },
  {
    field: "typeId",
    label: "deviceType",
    type: "select",
    resource: "device-types",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "serial",
    label: "serial",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "endpoint",
    label: "endpoint",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "status",
    label: "used",
    type: "switch",
  },
  {
    field: "enabled",
    label: "enabled",
    type: "switch",
  },
  {
    field: "alarmAt",
    label: "alarmAt",
    type: "date-time",
  },
  {
    field: "description",
    label: "description",
    type: "textarea",
  },
  {
    field: "images",
    label: "images",
    type: "images",
  },
  {
    field: "contractId",
    label: "contractId",
    type: "text",
  },
  {
    field: "contractInfo",
    label: "contractInfo",
    type: "textarea",
  },
];
