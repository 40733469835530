import {
  Alert,
  Button,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "../cruds/field";
import { getResource } from "resources";
import { validateMessages } from "provider/validateMessage";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { API_URL } from "provider/authProvider";

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const CrudEdit: React.FC<UpdateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const t = useTranslate();
  const { push } = useNavigation();

  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const [formFields, setFormFields] = useState<any[]>([]);
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });

  const [devices, setDevices] = useState<any[]>([]);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [selectDevice, setSelectDevice] = useState<any>(false);

  const getUsers = async () => {
    checkToken();
    const query = {
      page: 1,
      pageSize: 100,
      where: { companyId: queryResult?.data?.data?.id },
    };
    const url = `${API_URL}/v1/users?${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    getDevices(data.data);
  };

  const getDevices = async (users: any[]) => {
    checkToken();
    const query = {
      page: 1,
      pageSize: 100,
      where: { userId: { in: users.map((item) => item.id) } },
    };
    const url = `${API_URL}/v1/devices?${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setDevices(data.data);
    setDeviceTotal(data.totalRecords);
  };

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  const formFieldOne: any[] = [
    {
      field: "id",
      label: "ID",
      type: "hidden",
    },
    {
      field: "typeId",
      label: "industry",
      type: "select",
      resource: "company-types",
      select: {
        id: "id",
        label: "name",
      },
    },
    {
      field: "name",
      label: "companyName",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "phone",
      label: "phone",
      type: "text",
    },
    {
      field: "address",
      label: "address",
      type: "text",
    },
    {
      field: "website",
      label: "website",
      type: "text",
    },
    {
      field: "activeHour",
      label: "activeHour",
      type: "text",
    },
    {
      field: "images",
      label: "images",
      type: "images",
    },
  ];

  const formFieldTwo: any[] = [
    {
      field: "id",
      label: "ID",
      type: "hidden",
    },
    {
      field: "contractName",
      label: "contractName",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "email",
      label: "email",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "phone",
      label: "phone",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      field: "companyPhone",
      label: "companyPhone",
      type: "text",
    },
    {
      field: "companyEmail",
      label: "companyEmail",
      type: "text",
    },
    {
      field: "companyNumber",
      label: "companyNumber",
      type: "text",
      rules: [
        {
          required: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (queryResult?.data?.data?.companyId) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.data?.data?.companyId]);

  useEffect(() => {
    if (queryResult?.data?.data?.id) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.data?.data?.id]);

  return (
    <div className="flex w-full space-4 gap-4">
      <div className="w-5/12">
        <Edit saveButtonProps={saveButtonProps}>
          {deprecated === "deleted" && (
            <Alert
              message="This data is deleted."
              type="warning"
              style={{ marginBottom: 20 }}
              action={<ListButton size="small" />}
            />
          )}
          {deprecated === "updated" && (
            <Alert
              message="This data is updated. Refresh to see changes."
              type="warning"
              style={{ marginBottom: 20 }}
              action={<RefreshButton size="small" onClick={handleRefresh} />}
            />
          )}
          <Form
            {...formProps}
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={(values) => {
              if (onFinish) return onFinish(values, formProps);
              return formProps.onFinish && formProps.onFinish(values);
            }}
          >
            {formFieldOne.map((item) => (
              <Form.Item
                key={item.field}
                label={t(`crud.fields.${item.label}`)}
                name={item.field}
                rules={item.rules}
                valuePropName={item.type === "switch" ? "checked" : "value"}
                hidden={item.type === "hidden"}
              >
                <CrudField
                  type={item.type}
                  resource={item.resource}
                  select={item.select}
                />
              </Form.Item>
            ))}
            {children}
          </Form>
        </Edit>
      </div>
      <div className="w-5/12 ">
        <div
          className="w-full mb-4 p-2 shadow rounded p-2 text-base text-white"
          style={{ background: "#7b7b7b", color: "#ffffff" }}
        >
          <h4 className="text-white text-lg">
            {t("Linked sensor list")}: {deviceTotal}
          </h4>
          <h4 className="text-white text-lg">
            {t("Number of interlocking sensors")}: {deviceTotal}
          </h4>
          {devices.map((device, index) => (
            <h4
              onClick={() => setSelectDevice(device)}
              className={
                "text-white text-lg cursor-pointer" +
                (selectDevice.id === device.id ? " border-active" : "")
              }
            >
              [{index + 1}] {device.serial}
            </h4>
          ))}
          {selectDevice && (
            <div className="flex justify-center mt-4 items-center">
              <Button
                type="primary"
                onClick={() => push("/Device/edit/" + selectDevice.id)}
              >
                {t("View device")}
              </Button>
              <Button
                type="primary"
                onClick={() => push("/Store/edit/" + selectDevice.storeId)}
                className="ml-3"
              >
                {t("View store")}
              </Button>
            </div>
          )}
        </div>
        <div className="w-full hide-header mb-4 text-base">
          <Edit
            saveButtonProps={saveButtonProps}
            pageHeaderProps={{ backIcon: false, title: false, subTitle: false }}
          >
            {deprecated === "deleted" && (
              <Alert
                message="This data is deleted."
                type="warning"
                style={{ marginBottom: 20 }}
                action={<ListButton size="small" />}
              />
            )}
            {deprecated === "updated" && (
              <Alert
                message="This data is updated. Refresh to see changes."
                type="warning"
                style={{ marginBottom: 20 }}
                action={<RefreshButton size="small" onClick={handleRefresh} />}
              />
            )}
            <Form
              {...formProps}
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={(values) => {
                if (onFinish) return onFinish(values, formProps);
                return formProps.onFinish && formProps.onFinish(values);
              }}
            >
              {formFieldTwo.map((item) => (
                <Form.Item
                  key={item.field}
                  label={t(`crud.fields.${item.label}`)}
                  name={item.field}
                  rules={item.rules}
                  valuePropName={item.type === "switch" ? "checked" : "value"}
                  hidden={item.type === "hidden"}
                >
                  <CrudField
                    type={item.type}
                    resource={item.resource}
                    select={item.select}
                  />
                </Form.Item>
              ))}
              {children}
            </Form>
          </Edit>
        </div>
      </div>
    </div>
  );
};
