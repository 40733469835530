import { AntdLayout, Grid, Icons, Menu } from "@pankod/refine-antd";
import React, { useState } from "react";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { useLogout, useNavigation, useTranslate } from "@pankod/refine-core";

import { Title } from "../title";
import { getCrudData } from "resources";

const { SubMenu } = Menu;

const { RightOutlined, LogoutOutlined, MessageOutlined, DashboardTwoTone } = Icons;
declare type IMenuItem = {
  name: string;
  label: string;
  icon: string;
  route: string;
  child: any;
};

export const SideBar: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const t = useTranslate();
  //const { menuItems, selectedKey } = useMenu();
  const [selectedKey, setSelectedKey] = useState<string>("");
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const crudData = getCrudData();
  const menuItems: IMenuItem[] = crudData
    .filter((item: any) => !item.parentMenu)
    .map((item: any) => {
      return {
        name: item.name,
        label: t(`crud.menus.${item.name}`),
        icon: item.crud.icon,
        route: item.route,
        child: false,
      };
    });
  crudData
    .filter((item: any) => item.parentMenu)
    .forEach((item: any) => {
      const menuItem = {
        name: item.name,
        label: t(`crud.menus.${item.name}`),
        icon: item.crud.icon,
        route: item.route,
        child: false,
      };
      const index = menuItems.findIndex(
        (resource: any) => resource.name === item.parentMenu.name
      );
      if (index > -1) {
        menuItems[index].child.push(menuItem);
      }
      if (index === -1) {
        menuItems.splice(item.parentMenu.position, 0, {
          name: item.parentMenu.name,
          label: t(`crud.menus.${item.parentMenu.name}`),
          icon: item.parentMenu.icon,
          route: item.route,
          child: [menuItem],
        });
      }
    });

  const renderMenuItem = (menu: IMenuItem) => {
    const { icon, label, route, child } = menu;
    const isSelected = route === selectedKey;
    return child ? (
      renderSubMenuItem(menu)
    ) : (
      <Menu.Item
        style={{
          fontWeight: isSelected ? "bold" : "normal",
        }}
        className="side-bar-item"
        key={route}
        onClick={(): void => {
          setSelectedKey(route);
        }}
        icon={typeof icon === "string" ? false : icon}
      >
        {typeof icon === "string" && <i className={icon?.toString()} />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {label}
          {!collapsed && isSelected && <RightOutlined />}
        </div>
      </Menu.Item>
    );
  };

  const renderSubMenuItem = (menu: IMenuItem) => {
    const { icon, label, child } = menu;

    return (
      <SubMenu
        key={label}
        title={label}
        icon={<i className={icon?.toString()} />}
      >
        {child &&
          child.map((child: any) => {
            return renderMenuItem(child);
          })}
      </SubMenu>
    );
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      width={isMobile ? "100%" : 240}
      breakpoint="lg"
      theme="dark"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <Title collapsed={collapsed} />
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (key === "logout") {
            logout();
            return;
          }
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
          if (key === "dashboard") {
            return push("/");
          }
          push(key as string);
        }}
      >
        <Menu.Item
          onClick={() => setSelectedKey("dashboard")}
          key="dashboard"
          style={{
            fontWeight: selectedKey === "dashboard" ? "bold" : "normal",
          }}
          icon={<DashboardTwoTone />}
        >
          {t("crud.menus.Dashboard")}
        </Menu.Item>
        {menuItems.map((menu: IMenuItem) => renderMenuItem(menu))}
        <Menu.Item
          onClick={() => setSelectedKey("messenger")}
          key="messenger"
          style={{
            fontWeight: selectedKey === "messenger" ? "bold" : "normal",
          }}
          icon={<MessageOutlined />}
        >
          {t("crud.menus.Messenger")}
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          {t("buttons.logout", "Logout")}
        </Menu.Item>
      </Menu>
    </AntdLayout.Sider>
  );
};
