import { Button, Col, Form, Row } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { CrudField } from "./field";
import { validateMessages } from "provider/validateMessage";

const Filter: React.FC<{ onFinish: any; filters: any[] }> = ({
  onFinish,
  filters,
}) => {
  const t = useTranslate();
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={15}>
        {filters.map((item: any) => (
          <Col span={6} key={item.field}>
            <Form.Item
              key={item.field}
              label={t(`crud.fields.${item.label}`)}
              name={item.field}
              rules={item.rules}
              valuePropName={item.type === "switch" ? "checked" : "value"}
              hidden={item.type === "hidden"}
            >
              <CrudField
                type={item.type}
                resource={item.resource}
                select={item.select}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {t(`crud.action.filter`)}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Filter;
