import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 5;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const parentMenu = {
  position: 5,
  icon: "fab fa-accusoft",
  name: "Device",
};
export const name = "DeviceOperateState";
export const route = "/device-operates";
export const api = "v1/device-operates";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "from",
    label: "fromValue",
    type: "text",
  },
  {
    field: "to",
    label: "toValue",
    type: "text",
  },
  {
    field: "color",
    label: "color",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "from",
    label: "fromValue",
    type: "number",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "to",
    label: "toValue",
    type: "number",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "color",
    label: "color",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
];
