import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 5;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const parentMenu = {
  position: 5,
  icon: "fab fa-accusoft",
  name: "Device",
};
export const name = "Alarm";
export const route = "/alarms";
export const api = "v1/alarms";
export const allowExport = false;
export const allowImport = false;
export const disableCreate = true;
export const primaryId = "id";
export const listJoins = ["device"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "deviceId",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "device",
    label: "device",
    type: "select",
    resource: "devices",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "value",
    label: "value",
    type: "text",
  },
  {
    field: "average",
    label: "average",
    type: "text",
  },
  {
    field: "latitude",
    label: "latitude",
    type: "text",
  },
  {
    field: "longitude",
    label: "longitude",
    type: "text",
  },
  {
    field: "createdAt",
    label: "createdAt",
    type: "date",
  },
];

export const forms: any = [];
