import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 7;
export const crud = {
  icon: "fas fa-fas fa-image",
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Chat";
export const route = "/chats";
export const api = "v1/chats";
export const allowExport = false;
export const allowImport = false;
export const disableCreate = true;
export const primaryId = "id";
export const listJoins = ["user", "withUser"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
      where: {
        OR: [
          { groupId: 1, },
          { groupId: 2, }
        ]
      },
    }
  },
  {
    field: "withUserId",
    label: "withUser",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
      where: {
        groupId: 5,
      },
    }
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "user",
    label: "user",
    type: "select",
    select: {
      id: "id",
      label: "email",
    }
  },
  {
    field: "withUser",
    label: "withUser",
    type: "select",
    select: {
      id: "id",
      label: "email",
    }
  },
  {
    field: "createdAt",
    label: "createdAt",
    type: "date",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
      where: {
        OR: [
          { groupId: 1, },
          { groupId: 2, }
        ]
      },
    }
  },
  {
    field: "withUserId",
    label: "withUser",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "email",
      where: {
        groupId: 5,
      },
    }
  },
  {
    field: "title",
    label: "title",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
];
