import { Create, Form, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "./field";
import { getResource } from "resources";
import { validateMessages } from "provider/validateMessage";

export interface CreateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const CrudCreate: React.FC<CreateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const t = useTranslate();
  const [formFields, setFormFields] = useState<any[]>([]);
  const { formProps, saveButtonProps } = useForm<any>();

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        validateMessages={validateMessages}
        layout="vertical"
        onFinish={(values: any) => {
          const fieldHasValue = formFields.filter((field: any) => field.value);
          fieldHasValue.forEach((field: any) => {
            values[field.field] = field.value;
          });
          if (onFinish) return onFinish(values, formProps);
          return formProps.onFinish && formProps.onFinish(values);
        }}
      >
        {formFields.map((item) => (
          <Form.Item
            key={item.field}
            label={t(`crud.fields.${item.label}`)}
            name={item.field}
            rules={item.rules}
            valuePropName={item.type === "switch" ? "checked" : "value"}
            hidden={item.type === "hidden"}
          >
            <CrudField
              type={item.type}
              resource={item.resource}
              select={item.select}
              options={item.options ?? false}
            />
          </Form.Item>
        ))}
        {children}
      </Form>
    </Create>
  );
};
