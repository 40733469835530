import {
  useGetIdentity,
  useGetLocale,
  useLogout,
  useNavigation,
  useSetLocale,
  useTranslate,
} from "@pankod/refine-core";

import {
  AntdLayout,
  Avatar,
  Button,
  Dropdown,
  Icons,
  Menu,
  Space,
} from "@pankod/refine-antd";
import "../../../styles/header.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { initSocket, isHaveSocketToken } from "provider/socketClient";

const { DownOutlined } = Icons;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { mutate: logout } = useLogout();
  const { data: user } = useGetIdentity();

  const currentLocale = locale();
  const t = useTranslate();
  const { push } = useNavigation();

  const menu = (
    <Menu selectedKeys={[currentLocale]}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "Korea"}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menuCompany = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/CompanyType")}>
          {t(`crud.menus.CompanyType`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/StoreCategory")}>
          {t(`crud.menus.StoreCategory`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Store")}>
          {t(`crud.menus.Store`)}
        </Menu.Item>
      </Menu>
    );
  };

  const menuDevice = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/DeviceType")}>
          {t(`crud.menus.DeviceType`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/DeviceOperateState")}>
          {t(`crud.menus.DeviceOperateState`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Alarm")}>
          {t(`crud.menus.Alarm`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/BioAvgValue")}>
          {t(`crud.menus.BioAvgValue`)}
        </Menu.Item>
      </Menu>
    );
  };

  const menuSystemUser = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/Group")}>
          {t(`crud.menus.Group&Permission`)}
        </Menu.Item>
      </Menu>
    );
  };

  const menuChat = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/Chat")}>
          {t(`crud.menus.Chat`)}
        </Menu.Item>
      </Menu>
    );
  };

  const menuSetting = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/Notification")}>
          {t(`crud.menus.Notification`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Banner")}>
          {t(`crud.menus.Banner`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Module")}>
          {t(`crud.menus.Module`)}
        </Menu.Item>
      </Menu>
    );
  };

  const menuCustomer = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => push("/Manual")}>
          {t(`crud.menus.Manual`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Q-AOwner")}>
          {t(`crud.menus.Q-AOwner`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Q-AUser")}>
          {t(`crud.menus.Q-AUser`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Post")}>
          {t(`crud.menus.News&Announcement`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/Review")}>
          {t(`crud.menus.Review`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/ReviewComment")}>
          {t(`crud.menus.ReviewComment`)}
        </Menu.Item>
        <Menu.Item onClick={() => push("/ReviewReaction")}>
          {t(`crud.menus.ReviewReaction`)}
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    console.log(isHaveSocketToken)
    if (user && !isHaveSocketToken) {
      initSocket();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        height: "100px",
        backgroundColor: "#FFF",
      }}
      className="shadow-md"
    >
      <div className="flex justify-center items-center">
        <img
          style={{ margin: "0 auto", display: "block" }}
          src="/logo-main.png"
          width="140"
          alt="logo"
        />{" "}
        &nbsp;&nbsp;&nbsp;
        <Button className="button-header ml-10" onClick={() => push("/")}>
          {t(`crud.menus.Dashboard`)}
        </Button>
        <Dropdown overlay={menuDevice} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/Device")}
          >
            {t(`crud.menus.Device manager`)}
          </Button>
        </Dropdown>
        <Dropdown overlay={menuCompany} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/Company")}
          >
            {t(`crud.menus.Company manager`)}
          </Button>
        </Dropdown>
        <Dropdown overlay={menuCustomer} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/Customer")}
          >
            {t(`crud.menus.Customer manager`)}
          </Button>
        </Dropdown>
        <Dropdown overlay={menuSetting} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/Setting")}
          >
            {t(`crud.menus.Setting`)}
          </Button>
        </Dropdown>
        <Dropdown overlay={menuSystemUser} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/SystemUser")}
          >
            {t(`crud.menus.SystemUser`)}
          </Button>
        </Dropdown>
        <Dropdown overlay={menuChat} placement="bottomCenter">
          <Button
            className="button-header ml-3"
            onClick={() => push("/messenger")}
          >
            {t(`crud.menus.Messenger`)}
          </Button>
        </Dropdown>
      </div>
      <div className="flex justify-center items-center">
        <Dropdown overlay={menu}>
          <Button type="link">
            <Space>
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              {currentLocale === "en" ? "English" : "Korean"}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <div className="flex flex-col">
          <Button
            className="w-full mb-2"
            type="primary"
            onClick={() => push("/profile/" + user?.id)}
          >
            {t(`crud.menus.My Profile`)}
          </Button>
          <Button className="w-full" onClick={() => logout()}>
            {t(`crud.menus.Logout`)}
          </Button>
        </div>
      </div>
    </AntdLayout.Header>
  );
};
