import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 3;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const parentMenu = {
  position: 7,
  icon: "fas fa-comments",
  name: "Review",
};
export const name = "ReviewReaction";
export const route = "/review-reactions";
export const api = "v1/review-reactions";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["review", "user"];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "review",
    label: "review",
    type: "select",
    resource: "reviews",
    select: {
      id: "id",
      label: "content",
    }
  },
  {
    field: "user",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "name",
    }
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "reviewId",
    label: "review",
    type: "select",
    resource: "reviews",
    select: {
      id: "id",
      label: "content",
    }
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "name",
    }
  },
];
