import { CrudCreate, CrudList } from "pages/cruds";
import { CrudEdit } from "pages/company/edit";

export const position = 2;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Company";
export const parentMenu = {
  position: 1,
  icon: "fas fa-building",
  name: "Company",
};
export const route = "/companies";
export const api = "v1/companies";
export const allowExport = false;
export const allowImport = false;
export const disableCreate = true;
export const primaryId = "id";
export const listIncludes = { _count: { select: { stores: true } } };

export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "typeId",
    label: "industry",
    type: "select",
    resource: "company-types",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "userId",
    label: "user",
    type: "select",
    resource: "users",
    select: {
      id: "id",
      label: "username",
    }
  },
  {
    field: "name",
    label: "companyName",
    type: "text",
  },
  {
    field: "contractName",
    label: "contractName",
    type: "text",
  },
  {
    field: "email",
    label: "email",
    type: "text",
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "companyNumber",
    label: "companyNumber",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  // {
  //   field: "type",
  //   sort: "typeId",
  //   label: "type",
  //   type: "select",
  //   select: {
  //     id: "id",
  //     label: "name",
  //   }
  // },
  // {
  //   field: "user",
  //   label: "user",
  //   type: "select",
  //   resource: "users",
  //   select: {
  //     id: "id",
  //     label: "username",
  //   }
  // },
  {
    field: "name",
    label: "companyName",
    type: "text",
  },
  {
    field: "contractName",
    label: "contractName",
    type: "text",
  },
  {
    field: "email",
    label: "email",
    type: "text",
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "companyNumber",
    label: "companyNumber",
    type: "text",
  },
  {
    field: "_count.stores",
    label: "stores",
    type: "text_array",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "typeId",
    label: "industry",
    type: "select",
    resource: "company-types",
    select: {
      id: "id",
      label: "name",
    }
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "contractName",
    label: "contractName",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "email",
    label: "email",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "companyNumber",
    label: "companyNumber",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "website",
    label: "website",
    type: "text",
  },
  {
    field: "activeHour",
    label: "activeHour",
    type: "text",
  },
  {
    field: "images",
    label: "images",
    type: "images",
  },
];
