import {
  Alert,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  Switch,
  Table,
  useForm,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useList, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "../cruds/field";
import { getResource } from "resources";
import { validateMessages } from "provider/validateMessage";

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const GroupEdit: React.FC<UpdateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const t = useTranslate();

  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const [formFields, setFormFields] = useState<any[]>([]);
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });

  const { data: moduleData } = useList<any[]>({
    resource: "Module",
    config: {
      pagination: { current: 1, pageSize: 100 },
    },
    queryOptions: {
      staleTime: Infinity,
    },
  });

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      {deprecated === "deleted" && (
        <Alert
          message="This data is deleted."
          type="warning"
          style={{ marginBottom: 20 }}
          action={<ListButton size="small" />}
        />
      )}
      {deprecated === "updated" && (
        <Alert
          message="This data is updated. Refresh to see changes."
          type="warning"
          style={{ marginBottom: 20 }}
          action={<RefreshButton size="small" onClick={handleRefresh} />}
        />
      )}
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={(values) => {
          if (onFinish) return onFinish(values, formProps);
          return formProps.onFinish && formProps.onFinish(values);
        }}
      >
        <div className="flex w-full gap-5">
          <div className="w-4/12">
            {formFields.map((item) => (
              <Form.Item
                key={item.field}
                label={t(`crud.fields.${item.label}`)}
                name={item.field}
                rules={item.rules}
                valuePropName={item.type === "switch" ? "checked" : "value"}
                hidden={item.type === "hidden"}
              >
                <CrudField
                  type={item.type}
                  resource={item.resource}
                  select={item.select}
                />
              </Form.Item>
            ))}
          </div>
          <div className="w-8/12">
            <Table
              className="ant-table-permission"
              pagination={false}
              rowKey="code"
              dataSource={moduleData ? moduleData.data : []}
            >
              <Table.Column title={t("crud.fields.name")} dataIndex="name" />
              <Table.Column
                width={120}
                align="center"
                title={t("crud.permissions.all")}
                dataIndex="id"
                render={(value, row: any) => (
                  <Form.Item
                    key="all"
                    name={["permissions", row.code, "all"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
              />
              <Table.Column
                width={120}
                align="center"
                title={t("crud.permissions.owner")}
                dataIndex="id"
                render={(value, row: any) => (
                  <Form.Item
                    key="owner"
                    name={["permissions", row.code, "owner"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
              />
              <Table.Column
                width={120}
                align="center"
                title={t("crud.permissions.create")}
                dataIndex="id"
                render={(value, row: any) => (
                  <Form.Item
                    key="create"
                    name={["permissions", row.code, "create"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
              />
              <Table.Column
                width={120}
                align="center"
                title={t("crud.permissions.update")}
                dataIndex="id"
                render={(value, row: any) => (
                  <Form.Item
                    key="update"
                    name={["permissions", row.code, "update"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
              />
              <Table.Column
                width={120}
                align="center"
                title={t("crud.permissions.delete")}
                dataIndex="id"
                render={(value, row: any) => (
                  <Form.Item
                    key="delete"
                    name={["permissions", row.code, "delete"]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )}
              />
            </Table>
          </div>
        </div>
      </Form>
    </Edit>
  );
};
