import {
  Alert,
  Button,
  Edit,
  Form,
  ListButton,
  Modal,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "../cruds/field";
import { getResource } from "resources";
import { validateMessages } from "provider/validateMessage";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { API_URL } from "provider/authProvider";
import { FavoriteMap } from "./favorite-map";

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const CrudEdit: React.FC<UpdateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const t = useTranslate();
  const { push } = useNavigation();

  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const [formFields, setFormFields] = useState<any[]>([]);
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });

  const [stores, setStores] = useState<any[]>([]);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [devices, setDevices] = useState<any[]>([]);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [selectDevice, setSelectDevice] = useState<any>(false);
  const [showMapItem, setShowMapItem] = useState<any>(false);

  const getStores = async () => {
    checkToken();
    const query = {
      page: 1,
      pageSize: 100,
      where: { companyId: queryResult?.data?.data?.companyId },
    };
    const url = `${API_URL}/v1/stores?${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setStores(data.data);
  };

  const getDevices = async () => {
    checkToken();
    const query = {
      page: 1,
      pageSize: 100,
      where: { userId: queryResult?.data?.data?.id },
    };
    const url = `${API_URL}/v1/devices?${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setDevices(data.data);
    setDeviceTotal(data.totalRecords);
  };

  const getLocationFavorites = async () => {
    checkToken();
    const query = {
      page: 1,
      pageSize: 100,
      joins: ["store"],
      where: { userId: queryResult?.data?.data?.id },
    };
    const url = `${API_URL}/v1/favorite-locations?${new URLSearchParams({
      crudQuery: JSON.stringify(query),
    })}`;
    const { data } = await axiosInstance.get(url);
    setFavorites(data.data);
  };

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  const showMap = (item: any) => {
    setShowMapItem(item);
  };

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (queryResult?.data?.data?.companyId) {
      if (name) {
        const resource = getResource(name);
        setFormFields([...resource.forms, ...resource.formOwnerExtra]);
      }
      getStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.data?.data?.companyId]);

  useEffect(() => {
    if (queryResult?.data?.data?.id) {
      getDevices();
      getLocationFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.data?.data?.id]);

  return (
    <div className="flex w-full space-4 gap-4">
      <div className="w-5/12">
        <Edit saveButtonProps={saveButtonProps}>
          {deprecated === "deleted" && (
            <Alert
              message="This data is deleted."
              type="warning"
              style={{ marginBottom: 20 }}
              action={<ListButton size="small" />}
            />
          )}
          {deprecated === "updated" && (
            <Alert
              message="This data is updated. Refresh to see changes."
              type="warning"
              style={{ marginBottom: 20 }}
              action={<RefreshButton size="small" onClick={handleRefresh} />}
            />
          )}
          <Form
            {...formProps}
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={(values) => {
              if (onFinish) return onFinish(values, formProps);
              return formProps.onFinish && formProps.onFinish(values);
            }}
          >
            {formFields.map((item) => (
              <Form.Item
                key={item.field}
                label={t(`crud.fields.${item.label}`)}
                name={item.field}
                rules={item.rules}
                valuePropName={item.type === "switch" ? "checked" : "value"}
                hidden={item.type === "hidden"}
              >
                <CrudField
                  type={item.type}
                  resource={item.resource}
                  select={item.select}
                />
              </Form.Item>
            ))}
            {children}
          </Form>
          <h4 className="text-lg">{t("Location favorites")}:</h4>
          {favorites.map((item, index) => (
            <h4
              key={index}
              onClick={() => showMap(item)}
              className="text-lg cursor-pointer"
            >
              {index + 1}.{" "}
              {item.store
                ? `${item.store.name} - ${item.title}`
                : `${item.title}`}
            </h4>
          ))}
        </Edit>
      </div>
      <div
        className="w-5/12 p-2 shadow rounded p-2 text-base text-white"
        style={{ background: "#7b7b7b", color: "#ffffff" }}
      >
        <h4 className="text-white text-lg">
          {t("Linked sensor list")}: {deviceTotal}
        </h4>
        <h4 className="text-white text-lg">
          {t("Number of interlocking sensors")}: {deviceTotal}
        </h4>
        {devices.map((device, index) => (
          <h4
            onClick={() => setSelectDevice(device)}
            className={
              "text-white text-lg cursor-pointer" +
              (selectDevice.id === device.id ? " border-active" : "")
            }
          >
            [{index + 1}] {device.serial}
          </h4>
        ))}
        {selectDevice && (
          <div className="flex justify-center mt-4 items-center">
            <Button
              type="primary"
              onClick={() => push("/Device/edit/" + selectDevice.id)}
            >
              {t("View device")}
            </Button>
            <Button
              type="primary"
              onClick={() => push("/Store/edit/" + selectDevice.storeId)}
              className="ml-3"
            >
              {t("View store")}
            </Button>
          </div>
        )}
      </div>
      <FavoriteMap showMapItem={showMapItem} setShowMapItem={setShowMapItem} />
    </div>
  );
};
