// eslint-disable-next-line no-template-curly-in-string
const typeTemplate = "${label} is not a valid ${type}";

export const validatePassword = (rule: any, value: any, callback: any) => {
  if (!value) {
    // eslint-disable-next-line no-template-curly-in-string
    callback("${label} is required");
    return;
  }
  const matchOne = value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{6,}$/);
  const matchTwo = value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
  if (!matchOne && !matchTwo) {
    // eslint-disable-next-line no-template-curly-in-string
    callback("${label} is not a valid");
    return;
  }
  callback();
};

export const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  default: "Validation error on field ${label}",
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} is required",
  // eslint-disable-next-line no-template-curly-in-string
  enum: "${label} must be one of [${enum}]",
  // eslint-disable-next-line no-template-curly-in-string
  whitespace: "${label} cannot be empty",
  date: {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${label} is invalid for format date",
    // eslint-disable-next-line no-template-curly-in-string
    parse: "${label} could not be parsed as date",
    // eslint-disable-next-line no-template-curly-in-string
    invalid: "${label} is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    len: "${label} must be exactly ${len} characters",
    // eslint-disable-next-line no-template-curly-in-string
    min: "${label} must be at least ${min} characters",
    // eslint-disable-next-line no-template-curly-in-string
    max: "${label} cannot be longer than ${max} characters",
    // eslint-disable-next-line no-template-curly-in-string
    range: "${label} must be between ${min} and ${max} characters",
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    len: "${label} must equal ${len}",
    // eslint-disable-next-line no-template-curly-in-string
    min: "${label} cannot be less than ${min}",
    // eslint-disable-next-line no-template-curly-in-string
    max: "${label} cannot be greater than ${max}",
    // eslint-disable-next-line no-template-curly-in-string
    range: "${label} must be between ${min} and ${max}",
  },
  array: {
    // eslint-disable-next-line no-template-curly-in-string
    len: "${label} must be exactly ${len} in length",
    // eslint-disable-next-line no-template-curly-in-string
    min: "${label} cannot be less than ${min} in length",
    // eslint-disable-next-line no-template-curly-in-string
    max: "${label} cannot be greater than ${max} in length",
    // eslint-disable-next-line no-template-curly-in-string
    range: "${label} must be between ${min} and ${max} in length",
  },
  pattern: {
    // eslint-disable-next-line no-template-curly-in-string
    mismatch: "${label} does not match pattern ${pattern}",
  },
};
