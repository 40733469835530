import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <Link to="/">
      {collapsed ? (
        <img src={"/logo-main.png"} alt="App" className="logo-main-collapse" />
      ) : (
        <img src={"/logo-main.png"} alt="App" className="logo-main" />
      )}
    </Link>
  );
};
