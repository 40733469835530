import { CrudCreate, CrudList } from "pages/cruds";
import { CrudEdit } from "pages/users/edit";

export const position = 10;
export const crud = {
  icon: "fas fa-users-cog",
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Customer";
export const route = "/users";
export const api = "v1/users";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const listJoins = ["group"];
export const listIncludes = { _count: { select: { devices: true } } };
export const listFilters = [
  {
    field: "groupId",
    operator: "in",
    value: [1, 2],
  },
];
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "username",
    label: "username",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "group",
    label: "group",
    type: "select",
    select: {
      id: "id",
      label: "name",
    },
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "username",
    label: "username",
    type: "text",
    align: "left",
  },
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "_count.devices",
    label: "totalDevice",
    type: "text_array",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
  {
    field: "createdAt",
    label: "createdAt",
    type: "date",
    align: "center",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "groupId",
    label: "group",
    type: "select",
    resource: "groups",
    select: {
      id: "id",
      label: "name",
      where: {
        id: {
          in: [1, 2],
        },
      },
    },
  },
  {
    field: "companyId",
    label: "company",
    type: "select",
    resource: "companies",
    select: {
      id: "id",
      label: "name",
    },
  },
  {
    field: "name",
    label: "name",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "username",
    label: "username",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "email",
    label: "email",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "password",
    label: "password",
    type: "text",
    rules: [],
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "phone",
    label: "phone",
    type: "text",
  },
  {
    field: "address",
    label: "address",
    type: "text",
  },
  {
    field: "status",
    label: "status",
    type: "switch",
  },
];

export const formOwnerExtra: any = [
  {
    field: "branchInfo",
    label: "branchInfo",
    type: "textarea",
  },
  {
    field: "ownerInfo",
    label: "ownerInfo",
    type: "textarea",
  },
  {
    field: "paymentInfo",
    label: "paymentInfo",
    type: "textarea",
  },
  {
    field: "rentInfo",
    label: "rentInfo",
    type: "textarea",
  },
  {
    field: "detailInfo",
    label: "detailInfo",
    type: "textarea",
  },
  {
    field: "marketingInfo",
    label: "marketingInfo",
    type: "textarea",
  },
]