import {
  Alert,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { CrudField } from "./field";
import { getResource } from "resources";
import { validateMessages } from "provider/validateMessage";

export interface UpdateProps extends IResourceComponentsProps {
  onFinish?: any;
}

export const CrudEdit: React.FC<UpdateProps> = ({
  name,
  children,
  onFinish = false,
}) => {
  const t = useTranslate();

  // Live update
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const [formFields, setFormFields] = useState<any[]>([]);
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    setFormFields(resource.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      {deprecated === "deleted" && (
        <Alert
          message="This data is deleted."
          type="warning"
          style={{ marginBottom: 20 }}
          action={<ListButton size="small" />}
        />
      )}
      {deprecated === "updated" && (
        <Alert
          message="This data is updated. Refresh to see changes."
          type="warning"
          style={{ marginBottom: 20 }}
          action={<RefreshButton size="small" onClick={handleRefresh} />}
        />
      )}
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={(values) => {
          if (onFinish) return onFinish(values, formProps);
          return formProps.onFinish && formProps.onFinish(values);
        }}
      >
        {formFields.map((item) => (
          <Form.Item
            key={item.field}
            label={t(`crud.fields.${item.label}`)}
            name={item.field}
            rules={item.rules}
            valuePropName={item.type === "switch" ? "checked" : "value"}
            hidden={item.type === "hidden"}
          >
            <CrudField
              type={item.type}
              resource={item.resource}
              select={item.select}
              options={item.options ?? false}
            />
          </Form.Item>
        ))}
        {children}
      </Form>
    </Edit>
  );
};
