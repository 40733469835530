export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const handleError = (error: any, data: any = null): string => {
  let message = error.message;
  if (data.message && typeof data.message === "object") {
    message = data.message
      .map((item: string) => capitalizeFirstLetter(item))
      .join("\n");
  } else if (data.message) {
    message = capitalizeFirstLetter(data.message);
  }
  return message;
};
