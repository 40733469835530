import "styles/index.css";
import "styles/antd.less";
import "dayjs/locale/ko";

import {
  ConfigProvider,
  ErrorComponent,
  Layout,
  notificationProvider,
} from "@pankod/refine-antd";
import {
  Footer,
  Header,
  OffLayoutArea,
  SideBar,
  Title,
} from "components/layout";

import { DashboardPage } from "./pages/dashboard";
import { LoginPage } from "./pages/login";
import NestProvider from "provider/dataProvider";
import { Refine } from "@pankod/refine-core";
import { authProvider } from "provider/authProvider";
import dayjs from "dayjs";
import { getCrudData } from "resources";
import ko_KR from "antd/lib/locale/ko_KR";
import routerProvider from "@pankod/refine-react-router-v6";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ListMessenger } from "pages/chat";
import { Profile } from "pages/profile";

function App() {
  const { t, i18n } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const dataProvider = NestProvider(API_URL);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const locale = i18nProvider.getLocale();

  const crudData = getCrudData();
  const resources = crudData.map((item: any) => {
    return {
      name: item.name,
      options: {
        label: t(`crud.menus.${item.name}`),
      },
      icon: item.crud.icon,
      list: item.crud.list(),
      create: item.crud.create(),
      edit: item.crud.edit(),
      show: false,
    };
  });

  useEffect(() => {
    if (!locale) return;
    dayjs.locale(locale === "kr" ? "ko" : "en");
  }, [locale]);

  return (
    <ConfigProvider locale={locale === "kr" ? ko_KR : undefined}>
      <Refine
        // liveProvider={liveProvider(socketClient)}
        // liveMode="auto"
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <ListMessenger />,
              path: "/messenger",
              layout: true,
            },
            {
              element: <Profile name="SystemUser" />,
              path: "/profile/:id",
              layout: true,
            },
          ],
        }}
        dataProvider={dataProvider}
        authProvider={authProvider}
        resources={resources}
        Title={Title}
        Header={Header}
        Sider={OffLayoutArea}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        DashboardPage={DashboardPage}
        LoginPage={LoginPage}
        syncWithLocation
        warnWhenUnsavedChanges
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
      ></Refine>
    </ConfigProvider>
  );
}

export default App;
