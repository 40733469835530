import { Card, Col, Row, Typography } from "@pankod/refine-antd";

import { DeliveryMap } from "components";
import { useTranslation } from "react-i18next";
import { CrudListWithoutFilter } from "pages/cruds";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="shadow-md"
            bodyStyle={{
              padding: 0,
            }}
            title={
              <Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>
                {t("pages.dashboard.map")}
              </Text>
            }
          >
            <DeliveryMap />
          </Card>
        </Col>
      </Row>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 mb-0">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3 className="font-semibold text-base text-blueGray-700">
                    {t("Alarm history log")}
                  </h3>
                </div>
                <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                  <button
                    className="bg-nuriflex text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    See all
                  </button>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              <CrudListWithoutFilter name="Alarm" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
