import { Modal } from "@pankod/refine-antd";
import { NaverMap, Marker } from "react-naver-maps";

export const FavoriteMap = ({ showMapItem, setShowMapItem }) => {
  const navermaps = window.naver.maps;
  return (
    <Modal
      visible={showMapItem}
      title={showMapItem.title}
      onOk={() => setShowMapItem(false)}
      onCancel={() => setShowMapItem(false)}
      width={800}
      footer={null}
    >
      <NaverMap
        style={{
          width: "100%",
          height: "40vh",
        }}
        defaultZoom={8}
        center={{
          lat: showMapItem.latitude,
          lng: showMapItem.longitude,
        }}
        mapTypeControl={true}
        mapDataControl={true}
        scaleControl={true}
        zoomControl={true}
      >
        <Marker
          key={showMapItem.id}
          title={showMapItem.title}
          icon={{
            content: `<img alt="marker" src="/images/marker.png" style="width: 30px"/>`,
            size: new navermaps.Size(38, 58),
            anchor: new navermaps.Point(38, 58),
          }}
          position={
            new navermaps.LatLng(showMapItem.latitude, showMapItem.longitude)
          }
        />
      </NaverMap>
    </Modal>
  );
};
