const requireContext = require.context('./resources', false, /.*\.ts$/);
const resources: any = {};
const crudData : any = [];
function compare( a: any, b: any ) {
  if ( a.position < b.position ){
    return -1;
  }
  if ( a.position > b.position ){
    return 1;
  }
  return 0;
}

const modules = requireContext
.keys()
.map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
.forEach((item) => {
  if (!item[1] || resources[item[1].name]) return;
  const crud = item[1];
  resources[crud.name] = crud;
  crudData.push({
    position: crud.position,
    name: crud.name,
    crud: crud.crud,
    route: crud.name,
    parentMenu: crud.parentMenu,
  });
});

crudData.sort(compare);

export const getResource = (resource: string | null): any => {
  if (!resource || !resources[resource]) {
    throw new Error("Resource not found");
  }
  return resources[resource];
};

export const getCrudData = () => {
  return crudData;
}

export const getResourceApi = (resource: string): string => {
  if (!resources[resource]) {
    throw new Error("Resource not found");
  }
  return resources[resource].api;
};


export default modules;