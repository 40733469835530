import { Drawer, Pagination } from "@pankod/refine-antd";
import { imageUrl } from "provider/authProvider";
import { checkToken, getList } from "provider/dataProvider";
import { useEffect, useState } from "react";
import moment from "moment";

export const DrawerLike = ({ reviewId, setReviewId }) => {
  const [reviews, setReviews] = useState([]);
  const [reviewTotal, setReviewTotal] = useState(0);
  const [reviewPage, setReviewPage] = useState(1);

  const getReviewComment = async (page) => {
    checkToken();
    const pagination = {
      pageSize: 5,
      current: page,
    };
    const sorter = [{ field: "createdAt", order: "desc" }];
    const filters = [
      {
        field: "reviewId",
        operator: "eq",
        value: reviewId,
      },
    ];
    const metaData = {
      joins: ["user"],
    };
    const data = await getList(
      "ReviewReaction",
      pagination,
      filters,
      [sorter],
      metaData
    );
    if (data) {
      setReviews(data.data);
      setReviewTotal(data.total);
    }
  };

  const onChangePageReview = (page, pageSize) => {
    setReviewPage(page);
    getReviewComment(page);
  };

  const closeStoreDetail = () => {
    setReviewId(null);
  };

  useEffect(() => {
    getReviewComment(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return (
    <Drawer
      placement="right"
      onClose={closeStoreDetail}
      visible={reviewId}
    >
      <div className="row mt-3">
        {reviews.map((item) => (
          <div className="review-block col-12">
            <div className="row">
              <div className="col-6 inline-flex items-center">
                <div className="avatar">
                  {item?.user?.image && (
                    <img src={imageUrl(item.user.image)} alt="" />
                  )}
                </div>
                <div className="name ml-2">
                  {item.user.name}
                  <br />
                  {moment(item.createdAt).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        ))}
        <div className="col-12">
          {reviewTotal > 0 && (
            <Pagination
              defaultCurrent={reviewPage}
              pageSize={5}
              total={reviewTotal}
              onChange={onChangePageReview}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};
