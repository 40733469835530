import { useState } from "react";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

export const Markdown: React.FC<any> = (props) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  return (
    <ReactMde
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
      }
      {...props}
    />
  );
};
