import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 8;
export const crud = {
  icon: "fas fa-bell",
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const name = "Notification";
export const route = "/notifications";
export const api = "v1/notifications";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];

export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "type",
    label: "type",
    type: "select-option",
    options: [
      {
        id: 1,
        label: "Client"
      },
      {
        id: 2,
        label: "Owner"
      },
    ]
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "createdAt",
    label: "createdAt",
    type: "date",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "title",
    label: "title",
    type: "text",
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    field: "type",
    label: "type",
    type: "select-option",
    options: [
      {
        id: 1,
        label: "Client"
      },
      {
        id: 2,
        label: "Owner"
      },
    ]
  },
  {
    field: "shortContent",
    label: "shortContent",
    type: "textarea",
  },
  {
    field: "content",
    label: "content",
    type: "text-editor",
  },
  {
    field: "images",
    label: "images",
    type: "images",
  },
];
