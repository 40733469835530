import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "@pankod/refine-antd";
import { useEffect, useState } from "react";

import { API_URL } from "provider/authProvider";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { useTranslate } from "@pankod/refine-core";

export const Uploader: React.FC<any> = (props) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate();

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      checkToken();
      const { data } = await axiosInstance.post(
        `${API_URL}/v1/auth/upload`,
        fmData,
        config
      );
      props.onChange(data.data.url);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('crud.fields.upload')}</div>
    </div>
  );

  useEffect(() => {
    if (props.value) {
      setImageUrl(`${API_URL}${props.value}`);
    }
  }, [props.value]);

  return (
    <Upload
      accept="*"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={uploadImage}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt=""
          style={{ width: "100%", maxHeight: "100%" }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};
