import { Select } from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { axiosInstance, checkToken } from "provider/dataProvider";
import { API_URL } from "provider/authProvider";
import { CrudQuery } from "interfaces";

export const SelectItem: React.FC<any> = (props) => {
  const [selectValue, setSelectValue] = useState(false);
  const fetchRef = useRef(0);
  let searchText = "";
  const [selectData, setSelectData] = useState([]);

  const handleSearch = (value: string) => {
    fetchRef.current += 1;
    searchText = value;
    getData();
  };

  const handleChange = (value: any) => {
    setSelectValue(value);
    props.onChange(value);
  };

  const getData = async () => {
    checkToken();
    const crudQuery: CrudQuery = {
      page: 1,
      pageSize: 100,
      where: {
        [props.select.label]: { contains: searchText, mode: "insensitive" },
        ...props.select.where ?? {},
      },
    };
    const { data } = await axiosInstance.get(
      `${API_URL}/v1/${props.resource}?` +
        new URLSearchParams({ crudQuery: JSON.stringify(crudQuery) })
    );
    setSelectData(data.data);
  };

  useEffect(() => {
    if (props.value) {
      setSelectValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      filterOption={false}
      allowClear={true}
      showSearch={true}
      className="w-full"
      onSearch={debounce(handleSearch, 800)}
      value={selectValue}
      onChange={handleChange}
      multiple={props.multiple ?? false}
      {...props.multiple && { mode: "multiple" }}
    >
      {selectData &&
        selectData.map((item, index) => (
          <Select.Option key={index} value={item[props.select.id]}>
            {item[props.select.label]}
          </Select.Option>
        ))}
    </Select>
  );
};
