import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";

export const position = 1;
export const crud = {
  icon: "fas fa-fas fa-image",
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const name = "Banner";
export const route = "/banners";
export const api = "v1/banners";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";
export const filters: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "createdAt",
    label: "FromToDate",
    type: "date-range",
  },
];
export const tables: any = [
  {
    field: "id",
    label: "ID",
    type: "text",
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "image",
    label: "image",
    type: "image",
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "position",
    label: "position",
    type: "number",
  },
];
