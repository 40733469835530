import { CrudCreate, CrudEdit, CrudList } from "pages/cruds";
export const position = 9;
export const crud = {
  icon: false,
  list: () => CrudList,
  create: () => CrudCreate,
  edit: () => CrudEdit,
};
export const isModal = true;
export const parentMenu = {
  position: 9,
  icon: "fas fa-question",
  name: "Q&A",
};
export const name = "Q-AOwner";
export const route = "/advise-contents";
export const api = "v1/advise-contents-owner";
export const allowExport = false;
export const allowImport = false;
export const primaryId = "id";

export const tables: any = [
  {
    field: "title",
    label: "title",
    type: "text",
  },
];

export const forms: any = [
  {
    field: "id",
    label: "ID",
    type: "hidden",
  },
  {
    field: "type",
    label: "type",
    type: "hidden",
    value: 2,
  },
  {
    field: "title",
    label: "title",
    type: "text",
  },
  {
    field: "content",
    label: "content",
    type: "text-editor",
  },
];