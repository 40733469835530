import { API_URL, TOKEN_KEY } from "./authProvider";

import { io } from "socket.io-client";

const token = localStorage.getItem(TOKEN_KEY);
const options = {
  extraHeaders: {
    Authorization: `Bearer ${token}`,
  },
};
let socketClient = io(API_URL, options);
export const isHaveSocketToken = token !== null && token !== undefined;
export const initSocket = () => {
  socketClient.disconnect();
  const token = localStorage.getItem(TOKEN_KEY);
  socketClient.auth = {
    token,
  };
  socketClient.connect();
  return socketClient;
};
export default socketClient;
