import {
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  ExportButton,
  List,
  Space,
  Switch,
  Table,
  TextField,
  getDefaultSortOrder,
  notification,
  useModalForm,
  Image,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  CrudOperators,
  IResourceComponentsProps,
  useExport,
  usePermissions,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { API_URL } from "provider/authProvider";
import { getResource } from "resources";
import { CreateModal } from "../cruds/createModal";
import { EditModal } from "../cruds/editModal";
import Filter from "../cruds/filter";
import { axiosInstance, checkToken, getList } from "provider/dataProvider";
import { QRCodeSVG } from "qrcode.react";

export const CrudList: React.FC<IResourceComponentsProps> = ({ name }) => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions({
    staleTime: Infinity,
  });
  const [resourceInfo, setResourceInfo] = useState<any>(false);
  const [tableFields, setTableFields] = useState<any[]>([]);
  const { triggerExport, isLoading } = useExport<any>({
    mapData: (item) =>
      resourceInfo?.exportItem ? resourceInfo.exportItem(item) : item,
  });
  const { mutate } = useUpdate<any>();
  const [permission, setPermission] = useState<any>({
    list: false,
    create: false,
    update: false,
    delete: false,
  });
  const [isLoadingTable, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<any>({
    pageSize: 10,
    current: 1,
  });
  const [operateState, setOperateState] = useState<any[]>([]);
  const [tableSorter, setTableSorter] = useState<any>([
    {
      field: "id",
      order: "desc",
    },
  ]);
  const [tableFilter, setTableFilter] = useState<any>([]);
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: showModalCreate,
  } = useModalForm({
    resource: resourceInfo.resource,
    action: "create",
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: showModalEdit,
  } = useModalForm({
    resource: resourceInfo.resource,
    action: "edit",
  });

  const getOperateState = async () => {
    checkToken();
    const url = `${API_URL}/v1/device-operates`;
    const { data } = await axiosInstance.get(url);
    setOperateState(data.data);
  };

  const onChangeSwitch = (column: string, value: boolean, id: string) => {
    const values: any = {};
    values[column] = value;
    mutate({
      resource: resourceInfo.resource,
      id,
      values,
    });
  };

  const columnRender = (type: string, value: any, row: any, item: any) => {
    switch (type) {
      case "text":
        return <TextField value={value} />;
      case "text_array":
        const arrayField = item.field.split(".");
        let result = row;
        arrayField.forEach((field: string) => {
          if (typeof result[field] === undefined) return;
          result = result[field];
        });
        return <TextField value={result} />;
      case "switch":
        return (
          <Switch
            checked={value}
            onChange={(checked) => onChangeSwitch("backend", checked, row.id)}
          />
        );
      case "select":
        if (row[item.field]) {
          return <TextField value={row[item.field][item.select.label]} />;
        }
        return <div></div>;
      case "date":
        return <DateField value={value} format="LLL" />;
      case "image":
        return <Image height={100} src={API_URL + value} />;
      case "qrcode":
        return <QRCodeSVG value={value} />;
      case "operateState":
        const operateStateValue = operateState.find(
          (item) => value < item.to && value >= item.from
        );
        if (!operateStateValue) return <></>;
        return (
          <div className="flex gap-2 items-center">
            <div
              className="circle-operate"
              style={{ background: operateStateValue.color }}
            ></div>
            {operateStateValue.name}
          </div>
        );
      default:
        return <TextField value={value} />;
    }
  };

  const checkPermission = () => {
    if (!name || !permissionsData) return;
    const resource = name;
    if (permissionsData.isAdmin) {
      setPermission({
        list: true,
        create: resourceInfo.lockCreate ? false : true,
        update: true,
        delete: true,
      });
      return;
    }
    const permissions = permissionsData.permissions;
    if (!permissions || !permissions[resource]) {
      notification.error({
        message: t("notification.notice"),
        description: t("notification.notAccessTitle"),
      });
      setPermission({
        list: false,
        create: false,
        update: false,
        delete: false,
      });
      return;
    }
    setPermission({
      list: permissions[resource].all || permissions[resource].owner,
      create: resourceInfo.lockCreate ? false : permissions[resource].create,
      update: permissions[resource].update,
      delete: permissions[resource].delete,
    });
  };

  const getData = async (
    pagination: any,
    filters: any = [],
    sorter: any,
    metaData: any
  ) => {
    setTablePagination(pagination);
    setIsLoading(true);
    try {
      const data = await getList(name, pagination, filters, [sorter], metaData);
      if (data) {
        setTableData(data.data);
        setTableTotal(data.total);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleChange = async (pagination: any, filters: any, sorter: any) => {
    setTablePagination(pagination);
    setIsLoading(true);
    const metaData = {
      joins: resourceInfo.listJoins ?? [],
      includes: resourceInfo.listIncludes ?? {},
    };
    if (sorter) {
      sorter.field =
        tableFields.find((item: any) => item.field === sorter.field)?.sort ??
        sorter.field;
      sorter.order = sorter.order === "ascend" ? "asc" : "desc";
    }
    setTableSorter([sorter]);
    const data = await getList(name, pagination, [], [sorter], metaData);
    if (data) {
      setTableData(data.data);
      setTableTotal(data.total);
    }
    setIsLoading(false);
  };

  const onFinishFilter = (values: any) => {
    const filters: CrudFilters = [];
    if (!resourceInfo.filters) return filters;
    resourceInfo.filters.forEach((item: any) => {
      if (values[item.field] === undefined) return;
      let operator: Exclude<CrudOperators, "or"> = "eq";
      if (["text"].includes(item.type)) {
        operator = "containss";
      }
      if (["text"].includes(item.type) && !values[item.field]) {
        return;
      }
      if (["id"].includes(item.field)) {
        operator = "eq";
        values[item.field] = parseInt(values[item.field]);
      }
      if (["select", "number"].includes(item.type)) {
        values[item.field] = parseInt(values[item.field]);
      }
      if (["date-range", "select", "number"].includes(item.type) && !values[item.field]) {
        return;
      }
      if (["date-range"].includes(item.type) && values[item.field] && values[item.field].length > 1) {
        values[item.field] = {
          gte: values[item.field][0].toISOString(),
          lt:  values[item.field][1].toISOString()
        };
      }
      filters.push({
        field: item.field,
        operator,
        value: values[item.field],
      });
    });
    const listFilters = resourceInfo.listFilters ?? [];
    const fullFilters = [...listFilters, ...filters];
    setTableFilter(fullFilters);
    const metaData = {
      joins: resourceInfo.listJoins ?? [],
      includes: resourceInfo.listIncludes ?? {},
    };
    getData(tablePagination, fullFilters, tableSorter, metaData);
  };

  useEffect(() => {
    if (!name) return;
    const resource = getResource(name);
    getOperateState();
    setResourceInfo(resource);
    setTableFields(resource.tables);
    checkPermission();
    setTableData([]);
    setIsLoading(true);
    const pagination = {
      pageSize: 10,
      current: 1,
    };
    setTablePagination(pagination);
    const metaData = {
      joins: resource.listJoins ?? [],
      includes: resource.listIncludes ?? {},
    };
    const sort = {
      field: "id",
      order: "desc",
    };
    const listFilters = resource.listFilters ?? [];
    setTableFilter(listFilters);
    getData(pagination, listFilters, sort, metaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!permissionsData) return;
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsData]);

  return (
    <>
      <List
        pageHeaderProps={{
          extra: (
            <Space>
              {resourceInfo?.allowExport && (
                <ExportButton onClick={triggerExport} loading={isLoading} />
              )}
              {!resourceInfo.disableCreate &&
                permission.create &&
                resourceInfo.isModal && (
                  <CreateButton onClick={() => showModalCreate()} />
                )}
              {!resourceInfo.disableCreate &&
                permission.create &&
                !resourceInfo.isModal && <CreateButton />}
            </Space>
          ),
        }}
      >
        {resourceInfo.filters && (
          <Filter onFinish={onFinishFilter} filters={resourceInfo.filters} />
        )}
        <Table
          dataSource={tableData}
          loading={isLoadingTable}
          onChange={handleChange}
          pagination={{
            total: tableTotal,
            current: tablePagination.current,
            pageSize: tablePagination.pageSize,
          }}
          rowKey="id"
        >
          {tableFields.map((item) => (
            <Table.Column
              dataIndex={item.field}
              key={item.field}
              align={item.align ?? "left"}
              title={t(`crud.fields.${item.label}`)}
              render={(value: any, row: any) =>
                columnRender(item.type, value, row, item)
              }
              defaultSortOrder={getDefaultSortOrder(item.field, tableSorter)}
              sorter
            />
          ))}
          <Table.Column<any>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record: any) => (
              <Space>
                {resourceInfo.isModal && (
                  <EditButton
                    disabled={!permission.update}
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => {
                      showModalEdit(record.id);
                    }}
                  />
                )}
                {!resourceInfo.isModal && (
                  <EditButton
                    disabled={!permission.update}
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
                <DeleteButton
                  disabled={!permission.delete}
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              </Space>
            )}
          />
        </Table>
      </List>
      {name && (
        <CreateModal
          onFinish={false}
          name={name}
          modalProps={createModalProps}
          formProps={createFormProps}
        />
      )}
      {name && (
        <EditModal
          onFinish={false}
          name={name}
          modalProps={editModalProps}
          formProps={editFormProps}
        />
      )}
    </>
  );
};
